<template>
    <b-modal id="participantModal" title="Add Participants" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>Add Participants</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="participant" @submit.stop.prevent="onSubmit" ref="participantForm">
            <div class="text-danger mb-2" v-if="error">{{ error }}</div>
            <div class="mb-4">
                <label class="mb-2">Individuals <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="individualsToAdd" multiple size="15" required>
                    <option value="" disabled selected>Select an individual</option>
                    <option v-for="individual in nonParticipants" :key="individual.id" :value="individual.id">
                        {{ individual.firstName }} {{ individual.lastName }}
                    </option>
                </select>
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="adding">Add</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'AddParticipantModal',
	data() {
        return {
            individualsToAdd: [],
            tournamentId: 0,
            participants: [],
            individuals: [],
            adding: false,
            error: null
        }
    },

    created() {

    },

    computed: {
        nonParticipants() {
            let result = []
            for(let individual of this.individuals) {
                if(this.participants.find(x => x.id === individual.id) === undefined) {
                    result.push(individual)
                }
            }

            return result
        }
    },

    methods: {
        showModal() {
            this.$http.get('/individual/list').then((response) => {
                this.individuals = response.data
            })

            this.$bvModal.show('participantModal')
        },

        hideModal() {
            this.resetForm()
            this.$bvModal.hide('participantModal')
        },

        resetForm() {
            this.tournamentId = 0
            this.individualsToAdd = []
            this.participants = []
            this.individuals = []
        },

        onSubmit: function() {
            this.adding = true
            this.error = ''

            this.$store.dispatch('tournament:participants:add', { individuals: this.individualsToAdd, tournamentId: this.tournamentId })
                .then(() => {
                    this.adding = false
                    this.hideModal()
                    this.$emit('reloadTournament')
                })
                .catch(err => { 
                    this.adding = false
                    this.error = err.response.data.message
                })
        }
    }
}
</script>
