<script>
import Home from '../pages/Home'

import Login from '../pages/Login'
import Register from '../pages/Register'
import ForgotPassword from '../pages/ForgotPassword'
import ForgotPasswordComplete from '../pages/ForgotPasswordComplete'

import Sports from '../pages/Sports'
import SportOverview from '../pages/Sport/Overview'

import Groups from '../pages/Groups'
import GroupOverview from '../pages/Group/Overview'

import Organizations from '../pages/Organizations'
import OrganizationOverview from '../pages/Organization/Overview'

import Clubs from '../pages/Clubs'
import ClubOverview from '../pages/Club/Overview'

import Individuals from '../pages/Individuals'
import IndividualOverview from '../pages/Individual/Overview'

import Tournaments from '../pages/Tournaments'
import TournamentOverview from '../pages/Tournament/Overview'

/* Frontend */
import FHome from '../pages/Frontend/Home'

const routes = [
    {
        path: '*',
        redirect: '/manager/home',
        meta: {
            hideInMenu: true,
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            hideInMenu: true,
            notCache: true,
            pageEmpty: true
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            hideInMenu: true,
            notCache: true,
            pageEmpty: true
        }
    },
    {
        path: '/forgotpassword',
        name: 'forgotpassword',
        component: ForgotPassword,
        meta: {
            hideInMenu: true,
            notCache: true,
            pageEmpty: true
        }
    },
    {
        path: '/forgotpasswordcomplete',
        name: 'forgotpasswordcomplete',
        component: ForgotPasswordComplete,
        meta: {
            hideInMenu: true,
            notCache: true,
            pageEmpty: true
        }
    },
    {
        path: '/manager/home',
        name: 'Manager',
        component: Home,
        meta: {
            requiresAuth: true,
            title: 'Home',
            roles: [1]
        }
    },

    /* Sports */
    {
        path: '/manager/sports',
        name: 'Sports',
        component: Sports,
        meta: {
            requiresAuth: true,
            title: 'Sports',
            notCache: true,
            roles: [1]
        } 
    },
    {
        path: '/manager/sports/:id/:name',
        name: 'SportOverview',
        component: SportOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Sport "${route.params.name}"`,
            notCache: true,
            roles: [1]
        }
    },

    /* Groups */
    {
        path: '/manager/groups',
        name: 'Groups',
        component: Groups,
        meta: {
            requiresAuth: true,
            title: 'Groups',
            notCache: true,
            roles: [1]
        } 
    },
    {
        path: '/manager/groups/:id/:name',
        name: 'GroupOverview',
        component: GroupOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Group "${route.params.name}"`,
            notCache: true,
            roles: [1]
        }
    },

    /* Organizations */
    {
        path: '/manager/organizations',
        name: 'Organizations',
        component: Organizations,
        meta: {
            requiresAuth: true,
            title: 'Organizations',
            notCache: true,
            roles: [1]
        } 
    },
    {
        path: '/manager/organizations/:id/:name',
        name: 'OrganizationOverview',
        component: OrganizationOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Organization "${route.params.name}"`,
            notCache: true,
            roles: [1]
        }
    },
    
    /* Clubs */
    {
        path: '/manager/clubs',
        name: 'Clubs',
        component: Clubs,
        meta: {
            requiresAuth: true,
            title: 'Clubs',
            notCache: true,
            roles: [1]
        } 
    },
    {
        path: '/manager/clubs/:id/:name',
        name: 'ClubOverview',
        component: ClubOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Club "${route.params.name}"`,
            notCache: true,
            roles: [1]
        }
    },


    /* Individuals */
    {
        path: '/manager/individuals',
        name: 'Individuals',
        component: Individuals,
        meta: {
            requiresAuth: true,
            title: 'Individuals',
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/manager/individuals/:id/:name',
        name: 'IndividualOverview',
        component: IndividualOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Individual "${route.params.name}"`,
            notCache: true,
            roles: [1]
        }
    },


    /* Tournaments */
    {
        path: '/manager/tournaments',
        name: 'Tournaments',
        component: Tournaments,
        meta: {
            requiresAuth: true,
            title: 'Tournaments',
            notCache: true,
            roles: [1]
        }
    },
    {
        path: '/manager/tournaments/:id',
        name: 'TournamentOverview',
        component: TournamentOverview,
        props: true,
        meta: {
            requiresAuth: true,
            title: route => `Tournament #${route.params.id}`,
            notCache: true,
            roles: [1]
        }
    },


    /* Frontend */
    {
        path: '/',
        name: 'Frontend',
        component: FHome,
        meta: {
            requiresAuth: true,
            removeFromTagNav: true,
            title: 'Home',
            roles: [0, 1]
        }
    },

]

export default routes
</script>
