export default {
    state: {
        request: '',
        tournaments: []
    },
    mutations: {
        request(state) {
            state.request = ''
        },

        SET_TOURNAMENTS(state, data) {
            state.tournaments = data
        }
    },
    actions: {
        'tournament:create'({ commit }, tournament) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/tournament', tournament)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'tournament:load'({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.get('/tournament/' + id)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'tournament:remove'({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.delete(`/tournament/${id}`)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Join the tournament
        'tournament:join'({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/tournament/join/' + id)
                    .then(response => {
                        if (response.data.id)
                            resolve(response.data)
                        else
                            reject('Invalid tournament object.')
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Add multiple judges to the tournament.
        'tournament:judges:add'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/tournament/${data.tournamentId}/judges/add`, { individuals: data.individuals })
                    .then(response => {
                        if (response.data)
                            resolve(response.data)
                        else
                            reject('Invalid object.')
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Remove a judge from the tournament (and from all pre-assigned events)
        'tournament:judge:delete'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/tournament/${data.tournamentId}/judge/${data.judge.id}/delete`, data.judge)
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Add a participant to the tournament.
        'tournament:participant:add'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/tournament/${data.tournamentId}/participant/add`, { individualId: data.individualId })
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Add multiple participants to the tournament.
        'tournament:participants:add'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/tournament/${data.tournamentId}/participants/add`, { individuals: data.individuals })
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Remove a participant from the tournament (and all events)
        'tournament:participant:delete'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/tournament/${data.tournamentId}/participant/${data.individual.id}/delete`, data.individual)
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        /* Events */
        'event:create'({ commit }, event) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/event', event)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject('Could not create event.')
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'event:update'({ commit }, event) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.put('/event/' + event.id, event)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject('Could not update event.')
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'event:load'({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.get('/event/' + id)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject('Invalid event object.')
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        // Add or edit submission to event (This joins the event).
        'event:join'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/event/join', { eventId: data.id, submission: data.submission })
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject('Invalid event object')
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'event:scoresubmission'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/event/scoresubmission', data)
                    .then(response => {
                        if (response.data) {
                            resolve(response.data)
                        } else {
                            reject('Invalid event object')
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'event:delete'({ commit }, event) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.delete(`/event/${event.id}`, event)
                    .then(response => {
                        resolve()
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}