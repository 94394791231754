<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'Organizations' }">Organizations</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Organization #{{organization.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">{{organization.name}} <small>Details</small></h1>
        <!-- end page-header -->


        <template v-if="organization.name">
            
        </template>

        <NewClubModal ref="newClub" @reloadClubs="loadOrganization()" />

        <div class="row" v-if="organization.name">
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Organization Details" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-danger btn-xs" @click="handleDeleteOrganization()">Delete Organization</button>
                    </template>

                    <div class="text-center mb-3">
                        <Avatar :src="organization.image" :text="organization.name.charAt(0)" size="128px" type="organization" :id="organization.id" :changeable="true" />
                    </div>

                    <div class="form-horizontal form-bordered form-custom no-border">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">ID</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="organization.id" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="organization.name" @blur="saveOrganization()">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Sport</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="organization.group.sport.name" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Group</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="organization.group.name" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Description</label>
                                <div class="col-sm-8">
                                    <textarea class="form-control form-control-sm" rows="10" v-model="organization.description" @blur="saveOrganization()"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Rank Manager" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleSaveRanks()">Save Ranks</button>
                    </template>
                    <div class="note note-primary">
                        <div class="note-icon"><i class="fas fa-level-up-alt"></i></div>
                        <div class="note-content">
                            <h4><b>Rank Name Mapping</b></h4>
                            <p> Map the ranks to your desired name for the Group. </p>
                        </div>
                    </div>
                    <div class="table-responsive table-compact">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Default Name</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(rank, rankIdx) in ranks" :key="rankIdx">
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.rank"></td>
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.default" disabled></td>
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.name"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <panel title="Clubs" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleShowNewClub()">Create Club</button>
                    </template>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(club) in organization.clubs" :key="club.id">
                                    <td>{{club.id}}</td>
                                    <td><router-link :to="{ name: 'ClubOverview', params: { id: club.id, name: club.name }}">{{club.name}}</router-link></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <panel title="Individuals" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" disabled>Add Individual</button>
                    </template>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(individual) in organization.individuals" :key="individual.id">
                                    <td><router-link :to="{ name: 'IndividualOverview', params: { id: individual.id, name: individual.firstName + ' ' + individual.lastName }}">{{individual.firstName}} {{individual.lastName}}</router-link></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

import { mapMutations, mapActions } from 'vuex'
import NewClubModal from '@/components/modals/manager/NewClubModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'

export default {
    props: ['id'],
    components: {
        NewClubModal,
        Avatar
    },
    data () {
        return {
            organization: {},
            ranks: [],
            error: null
        }
    },
    created () {
        this.loadOrganization()
    },
    watch: {
        '$route' () { 
            this.loadOrganization()
        }
    },
    computed: {

    },
    methods: {
		...mapMutations([
			'closeTag'
		]),

        loadOrganization: function() {
            this.$store.dispatch('organization:load', this.id)
                .then(organization => {
                    this.organization = organization
                    this.parseRanks()
                })
                .catch(err => { 
                    this.error = err 
                })
        },

        parseRanks: function() {
            let ranks = []

            let sportRanks = this.organization.group.sport.ranks

            for(let rank of sportRanks) {
                let idx = ranks.push(rank) - 1

                let defaultRank = this.organization.group.sport.rankNames.find(x => x.rankId === ranks[idx].id)
                let organizationRank = this.organization.rankNames.find(x => x.rankId === ranks[idx].id)
                if(organizationRank === undefined)
                    ranks[idx].name = 'Undefined'
                else
                    ranks[idx].name = organizationRank.name

                if(defaultRank === undefined) 
                    ranks[idx].default = 'Undefined'
                else
                    ranks[idx].default = defaultRank.name
            }

            this.ranks = ranks
        },

        saveOrganization: function() {
            this.$http.put('/organization/' + this.organization.id, this.organization)
        },

        handleSaveRanks: function() {
            this.$http.put(`/organization/${this.organization.id}/ranks`, this.ranks).then(() => 
                this.loadOrganization()
            )
        },

        handleShowNewClub: function() {
            this.$refs.newClub.form.sport = this.organization.group.sport.id
            this.$refs.newClub.sportChanged({ target: { value: this.organization.group.sport.id }})
            this.$refs.newClub.form.group = this.organization.group.id
            this.$refs.newClub.groupChanged({ target: { value: this.organization.group.id }})

            this.$refs.newClub.form.organization = this.organization.id

            this.$refs.newClub.selectDisabled = true

            this.$refs.newClub.showModal()
        },

        handleDeleteOrganization: function() {
            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete organization "${this.organization.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('organization:remove', { id: this.organization.id }).then(() => {
                        this.$swal.fire('Removed', 'The organization has been successfully removed.', 'success')
                        this.closeTag(this.$route)
                    })
                }
            })
        }
    }
}
</script>