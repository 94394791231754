<template>
	<div class="app app-header-fixed app-sidebar-fixed" v-if="!appOptions.appEmpty" v-bind:class="{
		'app-sidebar-minified': appOptions.appSidebarMinified, 
		'app-content-full-height': appOptions.appContentFullHeight, 
		'app-without-sidebar': appOptions.appSidebarNone, 
		'app-with-end-sidebar': appOptions.appSidebarEnd, 
		'app-with-wide-sidebar': appOptions.appSidebarWide,
		'app-with-light-sidebar': appOptions.appSidebarLight,
		'app-sidebar-mobile-toggled': appOptions.appSidebarMobileToggled,
		'app-sidebar-end-toggled': appOptions.appSidebarEndToggled,
		'app-sidebar-end-collapsed': !appOptions.appSidebarEndToggled,
		'app-sidebar-end-mobile-toggled': appOptions.appSidebarEndMobileToggled,
		'app-header-fixed': !appOptions.appHeaderNone,
		'app-without-header': appOptions.appHeaderNone,
		'app-with-top-menu': appOptions.appTopMenu,
		'has-scroll': appOptions.appBodyScrollTop
	}">
		<notifications group="error" :duration="5000" />
		<notifications group="success" :duration="5000" />
		<Header />
		<TopMenu v-if="appOptions.appTopMenu" />
		<Sidebar v-if="!appOptions.appSidebarNone" />
		<div id="content" class="app-content" v-bind:class="appOptions.appContentClass">
			<div class="tag-nav-wrapper">
				<TagNavigation :value="$route" @input="handleClick" :list="tagNavList" @on-close="handleCloseTag" />
			</div>
			<keep-alive :include="cacheList">
				<router-view />
			</keep-alive>
			<vue-ins-progress-bar></vue-ins-progress-bar>
		</div>
	</div>
	<div class="h-100" v-else>
		<notifications group="error" :duration="5000" />
		<notifications group="success" :duration="5000" />
		<router-view />
		<vue-ins-progress-bar></vue-ins-progress-bar>
	</div>
</template>
<style>
	.vue-notification {
		text-align:left !important;
	}
</style>
<script>
import Sidebar from './components/sidebar/Sidebar.vue'
import Header from './components/header/Header.vue'
import TopMenu from './components/top-menu/TopMenu.vue'
import TagNavigation from './components/tag-navigation/TagNavigation.vue'
import AppOptions from './config/AppOptions.vue'

import { mapMutations, mapActions } from 'vuex'
import { getNewTagList, routeEqual } from './libs/util.js'

import routes from './config/AppRoutes.vue'

export default {
	name: 'app',
	components: {
		Sidebar,
		Header,
		TagNavigation,
		TopMenu
	},
	computed: {
		tagNavList () {
			const tagList = this.$store.state.app.tagNavList
			return tagList.filter(item => !(item.meta && item.meta.removeFromTagNav))
		},

		cacheList () {
			const list = ['ParentView', ...this.tagNavList.length ? this.tagNavList.filter(item => !(item.meta && item.meta.notCache)).map(item => item.name) : []]
			return list
		},
	},
	data() {
		return {
			appOptions: AppOptions
		}
	},
	methods: {
		handleScroll: function() {
			AppOptions.appBodyScrollTop = window.scrollY;
		},
		...mapMutations([
			'setBreadCrumb',
			'setTagNavList',
			'addTag',
			'closeTag',
			'setHomeRoute'
		]),
		...mapActions([
			'handleLogin'
		]),
		turnToPage (route) {
			let { name, params, query } = {}
			if (typeof route === 'string') 
				name = route
			else {
				name = route.name
				params = route.params
				query = route.query
			}

			if (name.indexOf('isTurnByHref_') > -1) {
				window.open(name.split('_')[1])
				return
			}
			this.$router.push({
				name,
				params,
				query
			})
		},
		handleCollapsedChange (state) {
			this.collapsed = state
		},
		handleCloseTag (res, type, route) {
			if (type !== 'others') {
				if (type === 'all') {
					this.turnToPage(this.appOptions.homeName)
				} else {
					if (routeEqual(this.$route, route)) {
						this.closeTag(route)
					}
				}
			}
			this.setTagNavList(res)
		},
		handleClick (item) {
			this.turnToPage(item)
		}
	},
	watch: {
		'$route' (newRoute) {
			const { name, query, params, meta } = newRoute

			if(meta.pageEmpty !== undefined && meta.pageEmpty)
				AppOptions.appEmpty = true
			else
				AppOptions.appEmpty = false

			if(meta.hideInMenu)
				return

			this.addTag({
				route: { name, query, params, meta },
				type: 'push'
			})
			this.setBreadCrumb(newRoute)
			this.setTagNavList(getNewTagList(this.tagNavList, newRoute))
		}
	},

	mounted () {
		this.$insProgress.finish()
		this.setTagNavList()
		this.setHomeRoute(routes)
	},

	created() {

		this.$http.interceptors.response.use((response) => {
			if(response.status === 200 && 
				response !== undefined && 
				response.data !== undefined &&
				response.data.message !== undefined && 
				response.data.message !== null) {

				this.$notify({
					group: 'success',
					title: `Success`,
					text : response.data.message,
					type: 'success'
				})
			}

			return response
		}, (error) => {
			if(!error.response) {
				console.log('No error response.')
				return Promise.reject(error)
			}

			const code = error.response.status

			if (code === 401) {
				this.$store.dispatch('individual:logout')
				AppOptions.appEmpty = true

				if(this.$route.name !== 'login')
					this.$router.push('/login')
			}

			if(code === 400 || code === 403) {
				this.$notify({
					group: 'error',
					title: `Error`,
					text : error.response.data.message,
					type: 'error'
				})
			}

			return Promise.reject(error)
		})

		AppOptions.appBodyScrollTop = window.scrollY
		
		window.addEventListener('scroll', this.handleScroll)
		
		this.$insProgress.start()
		
		this.$router.beforeEach((to, from, next) => {
			this.$insProgress.start()
			next()
		})

		this.$router.afterEach(() => {
			this.$insProgress.finish()
		})
	}
}
</script>
