import Vue from 'vue'
import VueX from 'vuex'

import individual from './module/individual'
import manager from './module/manager'
import sport from './module/sport'
import group from './module/group'
import organization from './module/organization'
import club from './module/club'
import tournament from './module/tournament'
import app from './module/app'

Vue.use(VueX)

export default new VueX.Store({
  state: {
    //
  },
  mutations: {

  },
  actions: {
    //
  },
  modules: {
    individual,
    manager,
    sport,
    group,
    organization,
    club,
    tournament,
    app
  }
})
