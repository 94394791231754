<script>
  const sidebarMenu = [
    { path: '/manager/home', icon: 'fa fa-th', title: 'Home' },
    { path: '/manager/sports', icon: 'fa fa-list-ol', title: 'Sports', highlight: true },
    { path: '/manager/groups', icon: 'fa fa-list-ol', title: 'Groups', highlight: true },
    { path: '/manager/organizations', icon: 'fa fa-list-ol', title: 'Organizations', highlight: true },
    { path: '/manager/clubs', icon: 'fa fa-list-ol', title: 'Clubs', highlight: true },
    { path: '/manager/individuals', icon: 'fa fa-address-card', title: 'Individuals'},
    { path: '/manager/tournaments', icon: 'fa fa-trophy', title: 'Tournaments', badge: '0' },
    { path: '/manager/billing', icon: 'fa fa-list-ol', title: 'Billing', highlight: true },

    { path: '/', icon: 'fa fa-th', title: 'Frontend' }
  ]

  export default sidebarMenu
</script>
