<template>
	<!-- BEGIN login -->
	<div class="login login-v1">
		<!-- BEGIN login-container -->
		<div class="login-container">
			<!-- BEGIN login-header -->
			<div class="login-header">
				<div class="brand">
					<div class="d-flex align-items-center">
						<span class="logo"></span> <b>Forgot</b> Password
					</div>
                    <small>Enter a new password</small>
				</div>
				<div class="icon">
					<i class="fa fa-lock"></i>
				</div>
			</div>
			<!-- END login-header -->
			
			<!-- BEGIN login-body -->
			<div class="login-body">
				<!-- BEGIN login-content -->
				<div class="login-content fs-13px">
                    <div>
                        <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
                        <form @submit.prevent="update">
                            <div class="form-floating mb-20px">
                                <input type="password" class="form-control fs-13px h-45px" id="password" v-model="password" placeholder="Password" required />
                                <label for="password" class="d-flex align-items-center py-0">Password</label>
                            </div>
                            <div class="form-floating mb-20px">
                                <input type="password" class="form-control fs-13px h-45px" id="password" v-model="repassword" placeholder="Password" required />
                                <label for="repassword" class="d-flex align-items-center py-0">Confirm Password</label>
                            </div>
                            <div class="login-buttons mb-4">
                                <button type="submit" class="btn h-45px btn-primary d-block w-100 btn-lg">Update Password</button>
                            </div>
                            <div>
                                <router-link to="/login">Login to your account</router-link>
                            </div>
                        </form>
                    </div>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-body -->
		</div>
		<!-- END login-container -->
	</div>
	<!-- END login -->
</template>

<script>
import AppOptions from '../config/AppOptions.vue'

export default {
	data() {
		return {
			error: "",
            hash: "",
            password : "",
            repassword: ""
		}
	},
	created() {
		AppOptions.appEmpty = true
	},
    mounted() {
        if(!this.$route.query.s) {
            return this.$router.push('/')
        }

        this.hash = this.$route.query.s
        this.checkHash()
    },
	beforeRouteLeave (to, from, next) {
		AppOptions.appEmpty = false
		next();
	},
	methods: {
        checkHash: function () {
            let hash = this.hash
            this.error = ''
            this.$store.dispatch('individual:forgotpasswordcheck', { hash })
                .then(result => { 
                    if(result.data.invalid) 
                        return this.$router.push('/')
                })
                .catch(err => { 
                    this.$router.push('/') 
                })
        },
        update: function () {
            let hash = this.hash
            let password = this.password
            let repassword = this.repassword
            this.error = ''
            this.$store.dispatch('individual:forgotpasswordcomplete', { hash, password, repassword })
                .then(() => { this.$router.push('/login') })
                .catch(err => { 
                    this.error = err 
                })
        },
	}
}
</script>