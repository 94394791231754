<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'Sports' }">Sports</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Sport #{{sport.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">{{sport.name}} <small>Details</small></h1>
        <!-- end page-header -->

        <template v-if="sport.name">
            
        </template>

        <NewGroupModal ref="newGroup" @reloadGroups="loadSport()" />

        <div class="row" v-if="sport.name">
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Sport Details" noButton="true">
                    <div class="text-center mb-3">
                        <Avatar :src="sport.image" :text="sport.name.charAt(0)" size="128px" type="sport" :id="sport.id" :changeable="true" />
                    </div>

                    <div class="form-horizontal form-bordered form-custom no-border">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">ID</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="sport.id" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="sport.name" @blur="saveSport()">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Description</label>
                                <div class="col-sm-8">
                                    <textarea class="form-control form-control-sm" v-model="sport.description" @blur="saveSport()"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Rank Manager" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs me-2" @click="handleSaveRanks()">Save Ranks</button>
                        <button class="btn btn-primary btn-xs" @click="handleAddRank()">Add Rank</button>
                    </template>

                    <div class="note note-warning note-with-icon mb-2">
                        <div class="note-icon"><i class="fa fa-exclamation-circle"></i></div>
                        <div class="note-content">
                            <h4><b>Warning</b></h4>
                            <p>Removing ranks will require updating all Events the rank is associated with.</p>
                        </div>
                    </div>

                    <div class="table-responsive table-compact">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(rank, rankIdx) in ranks" :key="rankIdx">
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.rank"></td>
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.name"></td>
                                    <td>
                                        <a href="javascript:;" class="btn btn-warning btn-xs" @click="handleRemoveRank(rankIdx, rank.id)"><i class="fas fa-times"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <panel title="Groups" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleShowNewGroup()">Create Group</button>
                    </template>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(group) in sport.groups" :key="group.id">
                                    <td>{{group.id}}</td>
                                    <td><router-link :to="{ name: 'GroupOverview', params: { id: group.id, name: group.name }}">{{ group.name }}</router-link></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <panel title="Individuals" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" disabled>Add Individual</button>
                    </template>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(individual) in sport.individuals" :key="individual.id">
                                    <td><router-link :to="{ name: 'IndividualOverview', params: { id: individual.id, name: individual.firstName + ' ' + individual.lastName }}">{{individual.firstName}} {{individual.lastName}}</router-link></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

import NewGroupModal from '@/components/modals/manager/NewGroupModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'

export default {
    props: ['id'],
    components: {
        NewGroupModal,
        Avatar
    },
    data () {
        return {
            sport: {},
            ranks: [],
            error: null
        }
    },
    created () {
        this.loadSport()
    },
    watch: {
        '$route' () { 
            this.loadSport()
        }
    },
    computed: {

    },
    methods: {
        loadSport: function() {
            this.$store.dispatch('sport:load', this.id)
                .then(sport => {
                    this.sport = sport
                    this.parseRanks()
                })
                .catch(err => { 
                    this.error = err 
                })
        },

        parseRanks: function() {
            let ranks = []

            for(let rank of this.sport.ranks) {
                let idx = ranks.push(rank) - 1

                let rankName = this.sport.rankNames.find(x => x.rankId === ranks[idx].id)
                if(rankName === undefined)
                    ranks[idx].name = 'Undefined'
                else
                    ranks[idx].name = rankName.name
            }

            this.ranks = ranks
        },

        saveSport: function() {
            this.$http.put('/sport/' + this.sport.id, this.sport)
        },

        handleSaveRanks: function() {
            this.$http.put(`/sport/${this.sport.id}/ranks`, this.ranks).then(() => 
                this.loadSport()
            )
        },

        handleRemoveRank: function(rankIdx, id) {
            this.$http.post(`/sport/${this.sport.id}/ranks/${id}/remove`).then(() => 
                this.ranks.splice(rankIdx, 1)
            )
        },

        handleAddRank: function() {
            this.$http.post(`/sport/${this.sport.id}/ranks/add`).then(() => 
                this.loadSport()
            )
        },

        handleShowNewGroup: function() {
            this.$refs.newGroup.form.sport = this.sport.id
            this.$refs.newGroup.selectDisabled = true

            this.$refs.newGroup.showModal()
        }
    }
}
</script>