<template>
    <b-modal id="tournamentModal" title="New Tournament" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>New Tournament</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="tournament" @submit.stop.prevent="onSubmit" ref="tournamentForm">
            <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" placeholder="Tournament Name" v-model="form.name" required />
            </div>
            <div class="mb-3">
                <label class="mb-2">Start Date <span class="text-danger">*</span></label>
                <div class="position-relative">
                    <date-picker id="startDateInput" class="form-control fs-13px" v-model="form.startdate" :config="options.datepicker" required></date-picker>
                </div>
                <small>Date from which individuals can join events.</small>
            </div>
            <div class="mb-3">
                <label class="mb-2">End Date <span class="text-danger">*</span></label>
                <div class="position-relative">
                    <date-picker id="endDateInput" class="form-control fs-13px" v-model="form.enddate" :config="options.datepicker"></date-picker>
                </div>
                <small>Date when entries are no longer accepted and events are started.</small>
            </div>
            <div class="mb-3">
                <label class="mb-2">Sport <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" @change="sportChanged($event)" v-model="form.sport" required>
                    <option value="" disabled selected>Select the appropriate sport</option>
                    <option v-for="sport in sports" :key="sport.id" :value="sport.id">
                        {{ sport.name }}
                    </option>
                </select>
                <small>Select the sport this tournament belongs to.</small>
            </div>
            <div class="mb-4" v-if="form.sport">
                <label class="mb-2">Group <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="form.group" required>
                    <option value="" disabled selected>Select the appropriate group</option>
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        {{ group.name }}
                    </option>
                </select>
                <small>Select the primary group of this tournament. (For ranks etc)</small>
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="!create">Create</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'NewTournamentModal',
	data() {
        return {
            form: {
                name: '',
                startdate: '',
                enddate: '',
                sport: null,
                group: null
            },
            sports: [],
            groups: [],
            error: null,
            create: true,
            options: {
                datepicker: {
                    format: 'MMMM DD, yyyy HH:mm:ss',
                    useCurrent: false,
                    icons: {
                        time: 'far fa-clock',
                        date: 'far fa-calendar',
                        up: 'fas fa-arrow-up',
                        down: 'fas fa-arrow-down',
                        previous: 'fas fa-chevron-left',
                        next: 'fas fa-chevron-right',
                        today: 'fas fa-calendar-check',
                        clear: 'far fa-trash-alt',
                        close: 'far fa-times-circle'
                    }
                }
            }
        }
    },

    created() {
        this.$store.dispatch('sport:getAll').then((sports) => {
            this.sports = sports.data
        })
    },

    methods: {
        showModal() {
            this.$bvModal.show('tournamentModal')
        },
        
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('tournamentModal')
        },
        
        createTournament(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.$refs.tournamentForm.submit()
        },

        resetForm() {
            this.form = {
                name: '',
                startdate: new Date(),
                enddate: new Date(),
                sport: null,
                group: null
            }
        },

        sportChanged: function(evt) {
			let sport = this.sports.find(x => x.id === Number(evt.target.value))
			if(sport !== undefined) {
				this.groups = sport.groups
			}

			this.form.group = null
        },

        onSubmit: function() {
            this.create = false

            this.error = ''

            this.$store.dispatch('tournament:create', this.form)
                .then(() => {
                    this.create = true
                    this.hideModal()
                    this.$emit('reloadTournaments')
                })
                .catch(err => { 
                    this.error = err
                    this.create = true
                })
        }
    }
}
</script>
