<template>
    <div>
        <AddEmergencyContactModal ref="emergencyContactModal" @addEmergencyContact="addEmergencyContact" />

        <div class="container" v-if="individual.firstName">
            <div class="pt-5">
                <ol class="breadcrumb hidden-print float-xl-end">
                    <li class="breadcrumb-item" v-if="$store.state.individual.roles === 1"><router-link :to="{ name: 'Manager' }"><button type="button" class="btn btn-default btn-sm d-block">Manager</button></router-link></li>
                    <li class="breadcrumb-item"><button type="button" class="btn btn-default btn-sm d-block" v-on:click="logout">Logout</button></li>
                </ol>
                <div class="float-start me-2">
                    <Avatar :src="individual.profilePicture" :text="individual.firstName.charAt(0) + individual.lastName.charAt(0)" size="64px" type="individual" :id="individual.id" :changeable="false" :hideEditText="true" />
                </div>
                <h1 class="page-header">{{ individual.firstName }} {{ individual.lastName }} <br><small>{{ individual.email }}</small></h1>
            </div>
            <div class="pt-4">
                <div class="panel panel-inverse panel-with-tabs">
                    <b-tabs v-model="tabIndex" nav-wrapper-class="nav nav-tabs nav-tabs-inverse" content-class="p-15px bg-white mb-4">
                        <b-tab>
                            <template slot="title">
                                Profile
                            </template>
                            <!--// start profile -->
                            <b-container>
                                <!-- show personal details -->
                                <b-row>
                                    <b-col cols="6">
                                        <b-row class="text-center" style="position: relative;">
                                            <Avatar :src="individual.profilePicture" :text="individual.firstName.charAt(0) + individual.lastName.charAt(0)" size="156px" type="individual" :id="individual.id" :changeable="true" :hideEditText="false" />


                                            <h4 style=""> {{ "" + individual.firstName + " " + individual.lastName }}</h4>
                                        </b-row>
                                        <b-row class="mt-5">
                                            <b class="text-inverse">PROFILE</b>
                                        </b-row>
                                        <b-row class="p-2">
                                            <!-- BEGIN table -->
                                            <div class="table-responsive form-inline">
                                                <table class="table align-middle">
                                                    <tbody>
                                                        <tr>
                                                            <th>Phone</th>
                                                            <td>{{ individual.phoneNumber }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>Email</th>
                                                            <td>{{ individual.email }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th class="field">Gender</th>
                                                            <td>{{ individual.gender }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th class="field">Birthdate</th>
                                                            <td>{{ individual.dob }} <b>({{ individual.age }})</b></td>
                                                        </tr>
                                                        <tr>
                                                            <th class="field">About</th>
                                                            <td>{{ individual.about }}</td>
                                                        </tr>
                                                        <tr class="divider">
                                                            <td colspan="2"></td>
                                                        </tr>
                                                        <tr>
                                                            <th class="field">Street</th>
                                                            <td>{{ individual.address }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th class="field">City</th>
                                                            <td>{{ individual.city }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th class="field">Country</th>
                                                            <td>{{ individual.country }}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <!-- END table -->
                                        </b-row>
                                        <b-row>
                                            <b class="text-inverse">EMERGENCY CONTACTS</b>
                                        </b-row>
                                        <b-row class="p-2">
                                            <div>
                                                <div class="table-responsive">
                                                    <table class="table">
                                                        <thead>
                                                            <tr>
                                                                <th>Name</th>
                                                                <th>Email</th>
                                                                <th>Number</th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <template v-for="(contact, index) in individual.emergencyContacts">
                                                                <tr v-if="contact" :key="contact.id">
                                                                    <td>{{ contact.name }}</td>
                                                                    <td>{{ contact.email }}</td>
                                                                    <td>{{ contact.phoneNumber }}</td>
                                                                    <td><button class="btn btn-sm btn-default" @click="removeEmergencyContact(index)"><i class="fas fa-times-circle"></i></button></td>
                                                                </tr>
                                                            </template>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="float-end">
                                                    <button class="btn btn-inverse btn-xs" @click="handleEmergencyContactModal()">Add Contact</button>
                                                </div>
                                            </div>
                                        </b-row>
                                    </b-col>
                                    <b-col cols="6">
                                        <b-row>
                                            <panel title="Invitations" noButton="true" class="mb-0">
                                                <div class="text-center">There are currently no invitations.</div>
                                            </panel>
                                            <panel title="Sports" noButton="true" class="mb-0">
                                                <div class="table-responsive" v-if="individual.sports.length > 0">
                                                    <table class="table">
                                                        <thead>
                                                            <tr class="d-flex">
                                                                <th class="col-1"></th>
                                                                <th class="col-5">Name</th>
                                                                <th class="col-4">Current Rank</th>
                                                                <th class="col-2"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(sport, sportIdx) in individual.sports" :key="sportIdx" class="d-flex align-items-center">
                                                                <td class="col-1">
                                                                    <Avatar :src="sport.image" :text="sport.name.charAt(0)" size="24px" type="sport" :id="sport.id" :changeable="false" />
                                                                </td>
                                                                <td class="col-5">
                                                                    {{ sport.name }} <span class="badge bg-inverse badge-xs" v-if="sport.id === individual.primarySport">Primary</span>
                                                                </td>
                                                                <td class="col-4" v-if="rankForSport(sport.id) !== -1">{{ rankNameFromSport(sport.id) }} <span v-if="rankForSport(sport.id).individualRank.verified === false"><b> (Unverified)</b></span></td>
                                                                <td class="text-right col-2">
                                                                    &nbsp;
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text-center" v-else>
                                                    You are not a member of any Sports.
                                                </div>
                                            </panel>
                                            <panel title="Groups" noButton="true" class="mb-0">
                                                <div class="table-responsive" v-if="individual.groups.length > 0">
                                                    <table class="table">
                                                        <thead>
                                                            <tr class="d-flex">
                                                                <th class="col-1"></th>
                                                                <th class="col-9">Name</th>
                                                                <th class="col-2"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(group, groupIdx) in individual.groups" :key="groupIdx" class="d-flex align-items-center">
                                                                <td class="col-1">
                                                                    <Avatar :src="group.image" :text="group.name.charAt(0)" size="24px" type="group" :id="group.id" :changeable="false" />
                                                                </td>
                                                                <td class="col-9">
                                                                    {{ group.name }} <span class="badge bg-inverse badge-xs" v-if="group.id === individual.primaryGroup">Primary</span>
                                                                </td>
                                                                <td class="text-right col-2">
                                                                    <button class="btn btn-xxs btn-inverse" @click="setPrimaryEntity('group', group.id)" v-if="group.id !== individual.primaryGroup">Set Primary</button>&nbsp;
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text-center" v-else>
                                                    You are not a member of any Groups.
                                                </div>
                                            </panel>
                                            <panel title="Organizations" noButton="true" class="mb-0">
                                                <div class="table-responsive" v-if="individual.organizations.length > 0">
                                                    <table class="table">
                                                        <thead>
                                                            <tr class="d-flex">
                                                                <th class="col-1"></th>
                                                                <th class="col-9">Name</th>
                                                                <th class="col-2"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(organization, organizationIdx) in individual.organizations" :key="organizationIdx" class="d-flex align-items-center">
                                                                <td class="col-1">
                                                                    <Avatar :src="organization.image" :text="organization.name.charAt(0)" size="24px" type="organization" :id="organization.id" :changeable="false" />
                                                                </td>
                                                                <td class="col-9">
                                                                    {{ organization.name }} <span class="badge bg-inverse badge-xs" v-if="organization.id === individual.primaryOrganization">Primary</span>
                                                                </td>
                                                                <td class="text-right col-2">
                                                                    <button class="btn btn-xxs btn-inverse" @click="setPrimaryEntity('organization', organization.id)" v-if="organization.id !== individual.primaryOrganization">Set Primary</button>&nbsp;
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text-center" v-else>
                                                    You are not a member of any Organizations.
                                                </div>
                                            </panel>
                                            <panel title="Clubs" noButton="true" class="mb-0">
                                                <div class="table-responsive" v-if="individual.clubs.length > 0">
                                                    <table class="table">
                                                        <thead>
                                                            <tr class="d-flex">
                                                                <th class="col-1"></th>
                                                                <th class="col-9">Name</th>
                                                                <th class="col-2"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(club, clubIdx) in individual.clubs" :key="clubIdx" class="d-flex align-items-center">
                                                                <td class="col-1">
                                                                    <Avatar :src="club.image" :text="club.name.charAt(0)" size="24px" type="club" :id="club.id" :changeable="false" />
                                                                </td>
                                                                <td class="col-9">
                                                                    {{ club.name }} <span class="badge bg-inverse badge-xs" v-if="club.id === individual.primaryClub">Primary</span>
                                                                </td>
                                                                <td class="text-right col-2">
                                                                    <button class="btn btn-xxs btn-inverse" @click="setPrimaryEntity('club', club.id)" v-if="club.id !== individual.primaryClub">Set Primary</button>&nbsp;
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div class="text-center" v-else>
                                                    You are not a member of any Clubs.
                                                </div>
                                            </panel>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-container>
                            <!--// end profile -->
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                Media
                            </template>
                            <vue-dropzone ref="Dropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" v-on:vdropzone-file-added="dropzoneFileAdded" v-on:vdropzone-success="dropzoneFileUploadSuccess" v-on:vdropzone-error="dropzoneFileUploadFailed">
                                <div class="dropzone-custom-content">
                                    <h3 class="dropzone-custom-title">Drag and drop to upload content!</h3>
                                    <div class="subtitle">...or click to select a file from your computer</div>
                                </div>
                            </vue-dropzone>

                            <h4 class="pt-4">Your media</h4>
                            <vue-good-table :columns="mediaColumns" :rows="media" :lineNumbers="false" :search-options="{ enabled: false, placeholder: 'Search term' }" :pagination-options="{ enabled: false, position: 'bottom' }" :selectOptions="{
                                enabled: false,
                                selectOnCheckboxOnly: true,
                                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                                selectionText: 'rows selected',
                                clearSelectionText: 'clear',
                            }">
                                <div slot="emptystate">
                                    <div class="vgt-center-align vgt-text-disabled">
                                        You have not uploaded any media.
                                    </div>
                                </div>
                                <template slot="table-row" slot-scope="props">
                                    <span v-if="props.column.field == 'videoId'">
                                        <div v-if="props.row.state.id === 8">
                                            <button type="button" class="btn btn-xxs btn-inverse" @click="mediaWatchVideo(props.row.id, props.row.fileName)">Watch Video</button>
                                        </div>
                                        <!--<a :href="'https://www.youtube.com/watch?v=' + props.row.videoId" target="_blank" v-if="props.row.videoId !== null">https://www.youtube.com/watch?v={{props.row.videoId}}</a>-->
                                    </span>
                                    <span v-else-if="props.column.field == 'after'">
                                        <a href="javascript:;" class="btn btn-default btn-xs" v-on:click="handleShowUpdateMediaModal(props.row)"><i class="fa fa-pencil-alt"></i></a>
                                        &nbsp;
                                        <a href="javascript:;" class="btn btn-default btn-xs"><i class="fa fa-trash-alt"></i></a>
                                    </span>
                                    <span v-else-if="props.column.field == 'status'">
                                        <span class="badge" :class="props.row.state.class">{{ props.row.state.name }}</span>
                                    </span>
                                    <span v-else>
                                        {{ props.formattedRow[props.column.field] }}
                                    </span>
                                </template>
                            </vue-good-table>
                            <VideoSlide :src="mediaCurrentVideo" :key="mediaCurrentVideoIndex" inline-template>
                                <div class="ratio ratio-16x9" id="video-template" v-if="src">
                                    <video id="videoPlayer" controls>
                                        <source :src="src" type="video/mp4">
                                    </video>
                                </div>
                            </VideoSlide>
                        </b-tab>
                        <b-tab>
                            <template slot="title">
                                Tournaments
                            </template>
                            <p class="pb-1">List of available tournaments.</p>
                            <div v-if="tournaments.length > 0">
                                <b-card no-body>
                                    <b-tabs pills card vertical>
                                        <b-tab v-for="(tournament, tournamentIdx) in tournaments" :key="tournament.id" :active="tournamentIdx === 0">
                                            <template slot="title">
                                                <i class="fas fa-calendar-alt"></i> {{ tournament.name }}
                                            </template>
                                            <div class="row">
                                                <h4 class="p-0 p-b-2">
                                                    {{ tournament.name }}
                                                </h4>
                                            </div>
                                            <div class="row">
                                                <div class="note note-inverse mb-0 text-white">
                                                    <div class="note-content">
                                                        <h4><b>Entries Open</b></h4>
                                                        <p class="mb-0">{{ tournament.startDate }}</p>
                                                    </div>
                                                </div>
                                                <div class="note note-inverse mb-0 text-white">
                                                    <div class="note-content">
                                                        <h4><b>Entries Close</b></h4>
                                                        <p class="mb-0">{{ tournament.endDate }}</p>
                                                    </div>
                                                </div>
                                                <div class="note note-inverse mb-0 text-white">
                                                    <div class="note-content">
                                                        <h4><b>Status</b></h4>
                                                        <p class="mb-0" :class="tournament.state.class">{{ tournament.state.name }}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row pt-4">
                                                <div class="alert alert-primary fade show mt-0" v-if="isInTournamentJudgePool(tournamentIdx)"><b>You are in this tournament's judge pool.</b></div>
                                                <!-- begin widget-todolist -->
                                                <div class="widget-todolist rounded inverse-mode" v-if="tournament.events.length > 0 && (hasJoinedTournament(tournament) || isInTournamentJudgePool(tournamentIdx))">
                                                    <!-- begin widget-todolist-header -->
                                                    <div class="widget-todolist-header">
                                                        <h4 class="widget-todolist-header-title"><b>Event List</b></h4>
                                                        <div class="widget-todolist-header-total">
                                                            <!--<span class="text-inverse">0</span>
                                                            <small>Done</small>-->
                                                        </div>
                                                    </div>
                                                    <!-- end widget-todolist-header -->

                                                    <!-- begin widget-todolist-body -->
                                                    <div class="widget-todolist-body">
                                                        <!-- begin widget-todolist-item -->
                                                        <div class="widget-todolist-item" v-for="(event, eventIdx) in tournament.events" :key="event.id">
                                                            <div class="widget-todolist-content">
                                                                <h5 class="mb-2px">{{ event.name }} <small :class="event.state.class"><b>({{ event.state.name }})</b></small></h5>
                                                                <div class="text-gray-500 fw-bold fs-11px">{{ getEventType(event.type) }}</div>

                                                                <div class="pt-2">
                                                                    <ul class="pb-0 mb-0">
                                                                        <li>For ranks between <span class="text-orange">{{ getRankName(tournamentIdx, event.minimumRank) }}</span> and <span class="text-orange">{{ getRankName(tournamentIdx, event.maximumRank) }}</span></li>
                                                                        <li>For ages between <span class="text-orange">{{ event.minimumAge }}</span> and <span class="text-orange">{{ event.maximumAge }}</span></li>
                                                                        <li>Required genders <span class="text-orange">{{ getRequiredGenders(event.requiredGender) }}</span></li>
                                                                    </ul>
                                                                </div>
                                                                <div class="pt-2" v-if="hasJoinedEvent(event.eventEntries)">
                                                                    <p class="text-green">You have joined this event.</p>
                                                                </div>
                                                                <div class="pt-2" v-else-if="!isJudgeOfEvent(tournamentIdx, eventIdx) && !hasJoinedEvent(event.eventEntries) && !tournamentPending(tournamentIdx)">
                                                                    <p class="text-orange">You didn't join this event.</p>
                                                                </div>
                                                                <div class="pt-2" v-if="isJudgeOfEvent(tournamentIdx, eventIdx)">
                                                                    <p class="text-orange"><b>You are a judge of this event.</b></p>
                                                                </div>
                                                            </div>
                                                            <div class="widget-todolist-icon text-gray-500">
                                                                <div v-if="tournamentPending(tournamentIdx)">
                                                                    <div v-if="canJoinEvent(tournamentIdx, eventIdx)"> <!-- Make sure we meet the event requirements and are not a judge -->
                                                                        <div v-if="!hasJoinedEvent(event.eventEntries)">
                                                                            <button type="button" class="btn btn-primary d-block w-100 btn-sm" @click="handleShowJoinEventModal(event)">Join</button>
                                                                        </div>
                                                                        <div v-else>
                                                                            <button type="button" class="btn btn-primary d-block w-100 btn-sm" @click="handleShowEditEventSubmissionModal(event)">Edit Submission</button>
                                                                        </div>
                                                                    </div>
                                                                    <div v-else>
                                                                        <span>Requirements not met</span>
                                                                    </div>
                                                                </div>
                                                                <div v-else-if="event.eventEntries.length === 0">
                                                                    <span>No submissions</span>
                                                                </div>
                                                                <!-- Until the event has concluded, prevent non judges from viewing submissions -->
                                                                <div v-else-if="!isJudgeOfEvent(tournamentIdx, eventIdx) && !eventConcluded(tournamentIdx, eventIdx)">
                                                                    <span>Being Judged</span>
                                                                </div>
                                                                <!-- Judge submissions -->
                                                                <div v-else-if="isJudgeOfEvent(tournamentIdx, eventIdx) && !eventConcluded(tournamentIdx, eventIdx)">
                                                                    <button type="button" class="btn btn-warning d-block w-100 btn-sm mt-3" @click="viewEvent(tournamentIdx, eventIdx)">Judge Submissions</button>
                                                                </div>
                                                                <div v-else>
                                                                    <div v-if="placementForEvent(tournamentIdx, eventIdx) > 0"><b>You placed {{ ordinalSuffice(placementForEvent(tournamentIdx, eventIdx)) }}</b></div>
                                                                    <button type="button" class="btn btn-primary d-block w-100 btn-sm mt-3" @click="viewEvent(tournamentIdx, eventIdx)">View Submissions</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- end widget-todolist-item -->
                                                    </div>
                                                    <!-- end widget-todolist-body -->
                                                </div>
                                                <div v-else-if="!hasJoinedTournament(tournament) && !tournamentFinished(tournamentIdx)">
                                                    <p class="text-center"><b>You need to join the tournament to participate in events.</b></p>
                                                    <div class="row">
                                                        <div class="col text-center">
                                                            <button type="button" class="btn btn-primary btn-sm" @click="joinTournament(tournamentIdx)" :disabled="!joinTournamentState">Join Tournament</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="!hasJoinedTournament(tournament) && tournamentFinished(tournamentIdx)">
                                                    <p class="text-center"><b>This tournament has concluded, you did not participate in any of the events.</b></p>
                                                </div>
                                                <div v-else>
                                                    <p class="text-center"><b>There are no events in this tournament.</b></p>
                                                </div>
                                                <!-- end widget-todolist -->
                                            </div>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </div>
                            <div v-else class="">
                                <p class="text-center">There is no tournaments available.</p>
                            </div>
                        </b-tab>
                        <b-tab v-show="isViewingEvent" v-if="selectedEvent">
                            <template slot="title">
                                <b-spinner type="border" small v-if="eventLoadingSpinner"></b-spinner> {{ selectedEvent.name }}
                            </template>
                            <div class="mb-1">
                                <h5>{{ selectedEvent.name }}</h5>
                                <div class="card p-3">
                                    <ul class="mb-0 ps-3">
                                        <li><strong>{{ getEventType(selectedEvent.type) }}</strong></li>
                                        <li><strong>{{ selectedEvent.eventEntries.length }} Participants</strong></li>
                                        <li>
                                            <strong>Judges</strong>
                                            <ul>
                                                <li v-for="judge in selectedEvent.judges" :key="judge.id" :set="currentIndividual = getIndividual(selectedTournamentIndex, judge.individualId, true)">
                                                    <template v-if="currentIndividual">
                                                        {{ currentIndividual.firstName }} {{ currentIndividual.lastName }} -
                                                        {{ getRankName(selectedTournamentIndex, getJudgeRank(selectedTournamentIndex, currentIndividual.id), currentIndividual.id) }}
                                                    </template>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <b-card no-body>
                                    <b-tabs card>
                                        <b-tab active>
                                            <template #title>
                                                <strong>Submissions</strong> <span class="badge bg-secondary">{{ selectedEvent.eventEntries.length }}</span>
                                            </template>
                                            <b-card-text>
                                                <div v-if="selectedEvent.type === 3">
                                                    <template v-if="selectedSubmission !== undefined">
                                                        <div class="mb-2">
                                                            <h5>Submission {{ selectedSubmissionIndex + 1 }} / {{ selectedEvent.eventEntries.length }}</h5>
                                                            <div class="row">
                                                                <div class="note note-inverse mb-0 text-white">
                                                                    <div class="note-content">
                                                                        <h4><b>Name</b></h4>
                                                                        <p class="mb-0">{{ selectedSubmission.individual.firstName }} {{ selectedSubmission.individual.lastName }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="note note-inverse mb-0 text-white">
                                                                    <div class="note-content">
                                                                        <h4><b>Age</b></h4>
                                                                        <p class="mb-0">{{ selectedSubmission.individual.age }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="note note-inverse mb-0 text-white">
                                                                    <div class="note-content">
                                                                        <h4><b>Rank</b></h4>
                                                                        <p class="mb-0">{{ getRankName(selectedTournamentIndex, getIndividualRank(selectedTournamentIndex, selectedSubmission.individual.id), selectedSubmission.individual.id) }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="mb-2">
                                                            <VideoSlide :src="`${mediaEndpoint}${selectedSubmission.media[0].fileName.split('.')[0]}`" @ended="submissionPlaybackEnded()" inline-template ref="submissionVideoRef">
                                                                <div class="ratio ratio-16x9" id="video-template" v-if="src">
                                                                    <video id="videoPlayer" controls @ended="ended()" ref="video">
                                                                        <source :src="src" type="video/mp4">
                                                                    </video>
                                                                </div>
                                                            </VideoSlide>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-2 text-start">
                                                                <button type="button" class="btn btn-inverse" :disabled="!canGoToPreviousSubmission" @click="selectedSubmissionIndex--">Previous Submission</button>
                                                            </div>

                                                            <!-- Not a judge -->
                                                            <div class="col-md-8 text-center" v-if="!canJudgeSubmissionForMatch && !hasJudgedSubmissionForMatch">
                                                                <template v-if="selectedEvent.displayJudgeScoresToAll && selectedSubmissionScoresByAverage.length > 0">
                                                                    <p class="text-center"><b>Scores</b></p>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="card p-3">
                                                                                <b-input-group v-for="(scorecategory, scoreIdx) in selectedSubmissionScoresByAverage" class="mb-3" :prepend="scorecategory.name" size="sm" :key="scoreIdx">
                                                                                    <b-form-input v-model="scorecategory.score" type="number" :min="0.0" :step="0.2" :max="10.0" disabled></b-form-input>
                                                                                </b-input-group>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </template>
                                                            </div>

                                                            <!-- Score the submission -->
                                                            <div class="col-md-8 text-center" v-if="canJudgeSubmissionForMatch && !hasJudgedSubmissionForMatch">
                                                                <p class="text-center"><b>Judges Panel</b></p>
                                                                <div class="card p-3">
                                                                    <b-input-group v-for="(scorecategory, scoreIdx) in submissionScores" class="mb-3" :prepend="scorecategory.name" size="sm" :key="scoreIdx">
                                                                        <b-form-input v-model="scorecategory.score" type="number" :min="0.0" :step="0.2" :max="10.0"></b-form-input>
                                                                    </b-input-group>

                                                                    <b-button text="Submit Score" variant="inverse" @click="scoreSubmission()">Submit Score</b-button>
                                                                </div>
                                                            </div>

                                                            <!-- You have scored the submission -->
                                                            <div class="col-md-8 text-center" v-if="canJudgeSubmissionForMatch && hasJudgedSubmissionForMatch">
                                                                <p class="text-center"><b>Judges Panel</b></p>
                                                                <div class="row">
                                                                    <div class="col-md-6">
                                                                        <div class="card p-3">
                                                                            <p>You have judged this submission.</p>
                                                                            <b-input-group v-for="(scorecategory, scoreIdx) in selectedSubmissionScoreBySelf" class="mb-3" :prepend="scorecategory.name" size="sm" :key="scoreIdx">
                                                                                <b-form-input v-model="scorecategory.score" type="number" :min="0.0" :step="0.2" :max="10.0" disabled></b-form-input>
                                                                            </b-input-group>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <div class="card p-3">
                                                                            <p>Average Scores for all judges.</p>
                                                                            <b-input-group v-for="(scorecategory, scoreIdx) in selectedSubmissionScoresByAverage" class="mb-3" :prepend="scorecategory.name" size="sm" :key="scoreIdx">
                                                                                <b-form-input v-model="scorecategory.score" type="number" :min="0.0" :step="0.2" :max="10.0" disabled></b-form-input>
                                                                            </b-input-group>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 text-end">
                                                                <button type="button" class="btn btn-inverse" v-if="!canGoToNextSubmission" @click="closeEventPanel">Close Player</button>
                                                                <button type="button" class="btn btn-inverse" v-if="canGoToNextSubmission" @click="goToNextSubmission()">Next Submission</button>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </div>
                                            </b-card-text>
                                        </b-tab>
                                        <b-tab title="Bracket" :disabled="selectedEvent.type === 3">
                                            <b-card-text>Bracket</b-card-text>
                                        </b-tab>
                                    </b-tabs>
                                </b-card>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>
        </div>

        <JoinEventModal ref="joinEventModal" @joinEvent="joinEvent()" />
        <UpdateMediaModal ref="updateMediaModal" @updateMedia="updateMedia()" />
    </div>
</template>

<style scoped>
.embed-responsive-item {
    min-height: 600px;
    width: 100%;
}
</style>

<script>

import AppOptions from '../../config/AppOptions.vue'
import JoinEventModal from '../../components/modals/JoinEventModal'
import UpdateMediaModal from '../../components/modals/UpdateMediaModal'
import AddEmergencyContactModal from '@/components/modals/AddEmergencyContactModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'
import Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

var VideoSlide = {
    props: ['src'],
    data() {
        return {
            autoPlay: true,
            isPlaying: false
        }
    },

    watch: {
        src: function (newVal, oldVal) {
            this.$refs.video.src = newVal
            this.$refs.video.load()

            if (this.autoPlay)
                this.$refs.video.play()
        }
    },

    methods: {
        videoEle() {
            return this.$refs.video
        },

        ended() {
            this.$emit('ended')
        },
    }
}

export default {
    name: 'Home',
    components: {
        JoinEventModal,
        UpdateMediaModal,
        Avatar,
        AddEmergencyContactModal,
        vueDropzone: Dropzone,
        VideoSlide
    },
    data() {
        return {
            individual: {},
            joinTournamentState: true,
            tournaments: [],
            ranks: [],
            media: [],
            tabIndex: 0,
            selectedTournamentIndex: 0,
            selectedEventIndex: 0,
            selectedMatchIndex: 0,
            selectedSubmissionIndex: 0, // When viewing the submissions (For judges and individuals)
            nextSubmissionButtonActive: false,
            eventLoadingSpinner: false,
            isViewingEvent: false,
            selectedEvent: null,
            judgeId: 0,
            submissionScores: [],
            mediaWatchingVideo: false,
            mediaCurrentVideo: '',
            mediaCurrentVideoIndex: 0,
            mediaEndpoint: `${process.env.VUE_APP_API_ENDPOINT}individual/media/`,
            eventTypes: [
                { id: 1, name: 'Head to Head Single Elimination' },
                { id: 2, name: 'Head to Head Double Elimination' },
                { id: 3, name: 'Point Based Competition' }
            ],

            dropzoneOptions: {
                url: `${process.env.VUE_APP_API_ENDPOINT}individual/media`,
                thumbnailWidth: 50,
                maxFilesize: 256.0,
                addRemoveLinks: true,
                timeout: 0,
                headers: { 'Authorization': this.$store.getters.token }
            },

            mediaColumns: [{
                label: 'Title',
                field: 'title',
                width: '20%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Description',
                field: 'description',
                width: '35%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Original Date',
                field: 'contentDate',
                type: 'date',
                width: '15%',
                thClass: 'text-nowrap',
                dateInputFormat: 'MMMM dd, yyyy',
                dateOutputFormat: 'MMMM dd, yyyy',
            }, {
                label: 'Video',
                field: 'videoId',
                width: '20%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Status',
                field: 'status',
                width: '10%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Options',
                field: 'after',
                width: '5%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }]
        }
    },

    created() {
        AppOptions.appEmpty = true
        this.getTournaments()
        this.getMedia()
    },

    mounted() {
        this.individual = this.$store.state.individual
    },

    watch: {

    },

    computed: {
        selectedSubmission() {
            return this.getCurrentMatch().eventEntries[this.selectedSubmissionIndex]
        },

        selectedSubmissionScoreBySelf() {
            let scores = this.selectedSubmission.matchScores.find(x => x.judgeId === this.judgeId).scores

            if (scores === undefined)
                return []

            return JSON.parse(scores)
        },

        selectedSubmissionScoresByAverage() {
            let result = []
            let scores = this.selectedSubmission.matchScores

            let judgeCount = scores.length
            let temp = {}

            for (let score of scores) {
                let scoreList = JSON.parse(score.scores)
                for (let i of scoreList) {

                    if (temp[i.name] === undefined) {
                        temp[i.name] = 0
                    }

                    temp[i.name] += Number(parseFloat(i.score).toFixed(2))
                }
            }

            for (let key in temp) {
                result.push({
                    name: key,
                    score: parseFloat(temp[key] / judgeCount).toFixed(2)
                })
            }

            return result
        },

        canGoToNextSubmission() {
            if (this.getCurrentMatch().eventEntries[this.selectedSubmissionIndex + 1] !== undefined) {
                return true
            }

            return false
        },

        canGoToPreviousSubmission() {
            return this.selectedSubmissionIndex > 0
        },

        canJudgeSubmissionForMatch() {
            if (!this.selectedEvent)
                return false

            let judge = this.selectedEvent.judges.find(x => x.individualId === this.$store.state.individual.id)

            if (judge) {
                this.setJudgeId(judge.id)
                return true
            }

            return false
        },

        hasJudgedSubmissionForMatch() {
            if (!this.selectedEvent)
                return false

            if (!this.canJudgeSubmissionForMatch)
                return false

            if (this.selectedSubmission.matchScores.find(x => x.judgeId === this.judgeId))
                return true

            return false
        },

        hasJudgedAllSubmissionsForMatch() {
            if (!this.selectedEvent)
                return false

            if (!this.canJudgeSubmissionForMatch)
                return false

            let scoredAll = true

            let entrants = this.getCurrentMatch().eventEntries
            for (let entrant of entrants) {
                let scored = entrant.matchScores.find(x => x.judgeId === this.judgeId).scores
                if (!scored)
                    scoredAll = false
            }

            return scoredAll
        }
    },

    methods: {
        /* PROFILE */
        saveIndividual() {
            this.$http.put('/individual/' + this.individual.id, this.individual).then(() => { })
        },

        setPrimaryEntity(entity, id) {
            this.$http.post('/individual/setPrimaryEntity', { entity: entity, id: id }).then((response) => {
                let result = response.data
                this.$store.state.individual[result.entity] = result.id
                this.individual[result.entity] = result.id

                this.$forceUpdate()
            })
        },

        rankForSport(sportId) {
            if (this.individual.ranks === undefined)
                return -1

            let rank = this.individual.ranks.find(x => x.sportId === sportId)

            if (rank === undefined)
                return -1

            return rank
        },

        rankNameFromSport(sportId) {
            let sport = this.individual.sports.find(x => x.id === sportId)
            let rank = this.rankForSport(sportId)
            if (!rank)
                return 'None'

            let rankName = sport.rankNames.find(x => x.rankId === rank.id)

            if (!rankName)
                return 'None'

            return rankName.name
        },

        /**
         * Load all tournaments
         */
        getTournaments: function () {
            this.$http.get('/tournament').then((response) => {
                this.tournaments = response.data
            })
        },

        /**
         * Load and replace single tournament in tournaments arr
         */
        getTournament: function (index) {
            this.$store.dispatch('tournament:load', this.tournaments[index].id).then((tournament) => {
                this.$set(this.tournaments, index, tournament)
            })
        },

        /**
         * Join the tournament
         */
        joinTournament: function (index) {
            this.joinTournamentState = false
            this.$store.dispatch('tournament:join', this.tournaments[index].id).then((tournament) => {
                this.$set(this.tournaments, index, tournament)
                this.joinTournamentState = true
            }).catch((error) => {
                this.joinTournamentState = true
            })
        },

        /**
         * Check if we've joined the tournament
         * @return Boolean
         */
        hasJoinedTournament: function (tournament) {
            if (tournament.individuals.find(x => x.id === this.$store.state.individual.id) !== undefined || tournament.judges.find(x => x.id === this.$store.state.individual.id) !== undefined)
                return true
            else
                return false
        },

        /**
         * Load and replace single event in tournament[idx].events arr
         */
        getEvent: function (tournamentIdx, eventIdx) {
            return this.tournaments[tournamentIdx].events[eventIdx]
        },

        joinEvent: function () {
            this.getTournaments()
        },

        canJoinEvent: function (tournamentIdx, eventIdx) {
            // Make sure we're not a judge.
            if (this.isJudgeOfEvent(tournamentIdx, eventIdx))
                return false

            let event = this.getEvent(tournamentIdx, eventIdx)
            if (!event)
                return false

            let currentRank = this.getIndividualRank(tournamentIdx, this.individual.id)
            if (!currentRank)
                return

            // Minimum Rank Requirement
            if (currentRank < event.minimumRank)
                return false

            // Maximum Rank Requirement
            if (currentRank > event.maximumRank)
                return false

            // Minimum Age Requirement
            if (this.individual.currentAge < event.minimumAge)
                return false

            // Maximum Age Requirement
            if (this.individual.currentAge > event.maximumAge)
                return false

            // Gender Requirement
            if (event.requiredGender.indexOf(this.individual.gender) === -1)
                return false

            return true
        },

        hasJoinedEvent: function (entries) {
            return entries.find(x => x.individualId === this.individual.id) !== undefined
        },

        // Get your placement for the event
        placementForEvent: function (tournamentIdx, eventIdx) {
            let event = this.getEvent(tournamentIdx, eventIdx)

            let placement = 0

            switch (event.type) {
                case 3: {// Point based

                    if (event.eventMatches === undefined || event.eventMatches === null || event.status < 5)
                        return placement

                    // There is only one match/round for point based.
                    let match = event.eventMatches[0]
                    let outcome = JSON.parse(match.outcome)
                    let outcomeSorted = outcome.sort(function (a, b) { return a.score - b.score })

                    let entry = event.eventEntries.find(x => x.individualId === this.$store.state.individual.id)

                    if (entry === undefined)
                        return placement

                    for (let num of outcomeSorted) {
                        if (entry.id === num.id)
                            return placement + 1

                        placement++
                    }
                    break
                }
            }

            return placement
        },

        /**
         * Load and replace single event in tournament[idx].events arr
         * Only loading it again so it's fully up-to-date.
         */
        viewEvent: function (tournamentIdx, eventIdx) {
            this.isViewingEvent = true
            this.eventLoadingSpinner = true
            this.selectedEvent = this.tournaments[tournamentIdx].events[eventIdx]

            this.$store.dispatch('event:load', this.selectedEvent.id)
                .then((event) => {
                    this.$set(this.tournaments[tournamentIdx], eventIdx, event)
                    this.selectedEvent = event

                    this.isViewingEvent = true
                    this.eventLoadingSpinner = false
                    this.tabIndex = 3
                    this.selectedTournamentIndex = tournamentIdx
                    this.selectedEventIndex = eventIdx

                    this.selectedSubmissionIndex = 0

                    this.setupScoreCategories()

                }).catch((error) => {
                    console.log(error)
                    this.isViewingEvent = false
                    this.tabIndex = 2
                    this.eventLoadingSpinner = false
                    this.selectedEvent = null,
                        this.selectedTournamentIndex = 0
                    this.selectedEventIndex = 0
                })
        },

        closeEventPanel: function () {
            this.isViewingEvent = false
            this.tabIndex = 2
            this.eventLoadingSpinner = false
            this.selectedEvent = null,
                this.selectedTournamentIndex = 0
            this.selectedEventIndex = 0
        },

        // Set up the score types (names) for the currently selected (if point based) event.
        setupScoreCategories() {
            this.submissionScores = []

            if (this.selectedEvent.type !== 3) { // Point based only
                return
            }

            let types = this.selectedEvent.scoreCategories
            for (let type of types) {

                this.submissionScores.push({
                    name: type.name,
                    score: 5.0
                })
            }
        },

        getCurrentMatch() {
            if (this.selectedEvent.eventMatches === undefined)
                return null

            return this.selectedEvent.eventMatches[this.selectedMatchIndex]
        },

        scoreSubmission() {
            let data = {
                eventId: this.selectedEvent.id,
                matchId: this.getCurrentMatch().id,
                judgeId: this.judgeId,
                entryId: this.selectedSubmission.id,
                scores: this.submissionScores
            }

            for (let score of this.submissionScores) {
                if (parseFloat(score.score).toFixed(2) > 10 || parseFloat(score.score).toFixed(2) < 0) {
                    this.$notify({
                        group: 'error',
                        title: `Error`,
                        text: 'Score must be between 0 and 10.',
                        type: 'error'
                    })

                    return
                }
            }

            this.$store.dispatch('event:scoresubmission', data)
                .then((event) => {

                    this.$notify({
                        group: 'success',
                        title: `Success`,
                        text: 'You have successfully judged the submission.',
                        type: 'success'
                    })

                    this.submissionScores = []
                    this.$set(this.tournaments[this.selectedTournamentIndex], this.selectedEventIndex, event)
                    this.selectedEvent = event

                    this.setupScoreCategories()
                })
        },

        isInTournamentJudgePool(tournamentIdx) {
            let tournament = this.tournaments[tournamentIdx]

            if (tournament.judges === undefined || tournament.judges === null || tournament.judges.length === 0)
                return false

            if (tournament.judges.find(x => x.individualId === this.$store.state.individual.id) !== undefined)
                return true

            return false
        },

        isJudgeOfEvent(tournamentIdx, eventIdx) {
            let event = this.tournaments[tournamentIdx].events[eventIdx]
            let tournamentJudges = this.tournaments[tournamentIdx].judges

            //let tournamentJudge = tournamentJudges.find(x => x.individual.id === this.$store.state.individual.id)

            if (event.judges === undefined || event.judges === null || event.judges.length === 0)
                return false

            if (event.judges.find(x => x.individualId === this.$store.state.individual.id) !== undefined)
                return true

            return false
        },

        setJudgeId(id) {
            this.judgeId = id
        },

        tournamentPending: function (index) {
            let tournament = this.tournaments[index]

            let startDate = this.$moment(tournament.startDate, 'MMMM DD, yyyy HH:mm:ss').toDate()
            let endDate = this.$moment(tournament.endDate, 'MMMM DD, yyyy HH:mm:ss').toDate()
            let date = new Date()

            if (tournament !== undefined && (date.getTime() > startDate.getTime() && date.getTime() < endDate.getTime()))
                return true

            return false
        },

        tournamentFinished: function (index) {
            let tournament = this.tournaments[index]

            if (tournament !== undefined) {
                if (tournament.state.concluded || tournament.state.closed)
                    return true
            }

            return false
        },

        eventConcluded: function (tournamentIdx, eventIdx) {
            let event = this.tournaments[tournamentIdx].events[eventIdx]

            if (event !== undefined && event.state.concluded)
                return true

            return false
        },

        getEventType: function (type) {
            return this.eventTypes.find(x => x.id === type).name
        },

        getIndividual: function (tournamentIdx, individualId, judge = false) {
            let tournament = this.tournaments[tournamentIdx]
            let individual = null

            if (!judge) {
                individual = tournament.individuals.find(x => x.id === individualId)
            }
            else {
                let judge = tournament.judges.find(x => x.individualId === individualId)
                if (judge) {
                    individual = judge.individual
                }
            }

            return individual
        },

        getIndividualRank: function (tournamentIdx, individualId) {
            let tournament = this.tournaments[tournamentIdx]
            let individual = tournament.individuals.find(x => x.id === individualId)

            if (individual === undefined || tournament === undefined)
                return -1

            let rank = individual.ranks.find(x => x.sportId === tournament.sportId)

            return rank.rank
        },

        getJudgeRank: function (tournamentIdx, individualId) {
            let tournament = this.tournaments[tournamentIdx]
            let individual = tournament.judges.find(x => x.individualId === individualId).individual

            let rank = individual.ranks.find(x => x.sportId === tournament.sportId)

            return rank.rank
        },

        getRankName: function (tournamentIdx, rankId, individualId = null) {
            let rankName = ''
            let extraName = null

            let tournament = this.tournaments[tournamentIdx]
            let sport = tournament.sport
            let rank = sport.ranks.find(x => x.rank === rankId)
            let rankNumber = rank.id
            let sportRank = sport.rankNames.find(x => x.rankId === rank.id)

            rankName = sportRank.name

            let searchId = individualId !== null ? individualId : this.$store.state.individual.id

            let individual = tournament.individuals.find(x => x.id === searchId)

            if (individual === undefined)
                return rankName

            let individualGroup = null
            let individualOrganization = null
            let individualClub = null

            individualGroup = sport.groups.find(x => x.id === individual.primaryGroup)
            if (individualGroup) {
                individualOrganization = individualGroup.organizations.find(x => x.id === individual.primaryOrganization)
            }

            if (individualOrganization) {
                individualClub = individualOrganization.clubs.find(x => x.id === individual.primaryClub)
            }

            if (individualClub) {
                extraName = individualClub.rankNames.find(x => x.rankId === rankNumber)
            }

            if (!extraName && individualOrganization) {
                extraName = individualOrganization.rankNames.find(x => x.rankId === rankNumber)
            }

            if (!extraName && individualGroup) {
                extraName = individualGroup.rankNames.find(x => x.rankId === rankNumber)
            }

            if (extraName)
                return `${rankName} - ${extraName.name}`
            else
                return rankName
        },


        getRequiredGenders: function (genders) {
            const data = genders
            return data.join(', ')
        },

        getMedia: function () {
            this.$store.dispatch('individual:getMedia').then((media) => {
                this.media = media
            })
        },

        getMediaStateName: function (state) {
            return this.mediaStates.find(x => x.id === state).name
        },

        getMediaStateColor: function (state) {
            return this.mediaStates.find(x => x.id === state).color
        },

        mediaWatchVideo: function (index, fileName) {
            this.mediaCurrentVideoIndex = index
            this.mediaWatchingVideo = true

            let id = fileName.split('.')

            this.mediaCurrentVideo = `${process.env.VUE_APP_API_ENDPOINT}individual/media/${id[0]}`
        },

        handleShowUpdateMediaModal: function (media) {
            this.$refs.updateMediaModal.media = media
            this.$refs.updateMediaModal.showModal()
        },

        updateMedia: function () {
            this.getMedia()
        },

        /* Uploads */
        dropzoneFileAdded: function (file) {

        },

        dropzoneFileUploadSuccess: function (file, response) {
            this.getMedia()
        },

        dropzoneFileUploadFailed: function (file, message, xhr) {
            this.$notify({
                group: 'error',
                title: `Error`,
                text: message,
                type: 'error'
            })
        },

        handleShowJoinEventModal: function (event) {
            this.$refs.joinEventModal.event = event
            this.$refs.joinEventModal.edit = false
            this.$refs.joinEventModal.showModal()
        },

        handleShowEditEventSubmissionModal: function (event) {
            this.$refs.joinEventModal.event = event
            this.$refs.joinEventModal.edit = true
            this.$refs.joinEventModal.showModal()
        },

        logout() {
            this.$store.dispatch('individual:logout')
            this.$router.push('/login')
        },

        editProfileDetails() {

        },

        handleEmergencyContactModal: function () {
            this.$refs.emergencyContactModal.showModal()
        },

        addEmergencyContact: function (item) {
            this.individual.emergencyContacts.push(item)
            this.$forceUpdate()
            this.saveIndividual()
        },

        removeEmergencyContact: function (index) {
            this.individual.emergencyContacts.splice(index, 1)
            this.$forceUpdate()
            this.saveIndividual()
        },

        ordinalSuffice(i) {
            let j = i % 10,
                k = i % 100

            if (j == 1 && k != 11)
                return i + "st"

            if (j == 2 && k != 12)
                return i + "nd"

            if (j == 3 && k != 13)
                return i + "rd"

            return i + "th"
        },

        goToNextSubmission: function () {
            let judgeOfEvent = this.isJudgeOfEvent(this.selectedTournamentIndex, this.selectedEventIndex)
            if (judgeOfEvent)
                this.nextSubmissionButtonActive = false

            this.selectedSubmissionIndex++
        },

        /* Submission Video Automation */
        submissionPlaybackEnded: function () {
            let judgeOfEvent = this.isJudgeOfEvent(this.selectedTournamentIndex, this.selectedEventIndex)

            if (this.canGoToNextSubmission && !judgeOfEvent || judgeOfEvent && this.hasJudgedAllSubmissionsForMatch) {
                this.$refs.submissionVideoRef.autoPlay = true
            }

            if (judgeOfEvent)
                this.nextSubmissionButtonActive = true
        }
    }
}

</script>