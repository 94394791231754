<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'Groups' }">Groups</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Group #{{group.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">{{group.name}} <small>Details</small></h1>
        <!-- end page-header -->


        <template v-if="group.name">
            
        </template>

        <NewOrganizationModal ref="newOrganization" @reloadOrganizations="loadGroup()" />

        <div class="row" v-if="group.name">
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Group Details" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-danger btn-xs" @click="handleDeleteGroup()">Delete Group</button>
                    </template>

                    <div class="text-center mb-3">
                        <Avatar :src="group.image" :text="group.name.charAt(0)" size="128px" type="group" :id="group.id" :changeable="true" />
                    </div>

                    <div class="form-horizontal form-bordered form-custom no-border">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">ID</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="group.id" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="group.name" @blur="saveGroup()">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Sport</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="group.sport.name" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Description</label>
                                <div class="col-sm-8">
                                    <textarea class="form-control form-control-sm" rows="10" v-model="group.description" @blur="saveGroup()"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Rank Manager" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs me-2" @click="handleSaveRanks()">Save Ranks</button>
                    </template>
                    <div class="note note-primary">
                        <div class="note-icon"><i class="fas fa-level-up-alt"></i></div>
                        <div class="note-content">
                            <h4><b>Rank Name Mapping</b></h4>
                            <p> Map the ranks to your desired name for the Group. </p>
                        </div>
                    </div>
                    <div class="table-responsive table-compact">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Rank</th>
                                    <th>Default Name</th>
                                    <th>Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(rank, rankIdx) in ranks" :key="rankIdx">
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.rank"></td>
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.default" disabled></td>
                                    <td><input type="text" class="form-control form-control-sm" v-model="rank.name"></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <panel title="Clubs" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleShowNewOrganization()">Create Organization</button>
                    </template>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(organization) in group.organizations" :key="organization.id">
                                    <td>{{organization.id}}</td>
                                    <td><router-link :to="{ name: 'OrganizationOverview', params: { id: organization.id, name: organization.name }}">{{organization.name}}</router-link></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
            <div class="col-md-3">
                <panel title="Individuals" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" disabled>Add Individual</button>
                    </template>

                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(individual) in group.individuals" :key="individual.id">
                                    <td><router-link :to="{ name: 'IndividualOverview', params: { id: individual.id, name: individual.firstName + ' ' + individual.lastName }}">{{individual.firstName}} {{individual.lastName}}</router-link></td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

import { mapMutations, mapActions } from 'vuex'
import NewOrganizationModal from '@/components/modals/manager/NewOrganizationModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'

export default {
    props: ['id'],
    components: {
        NewOrganizationModal,
        Avatar
    },
    data () {
        return {
            group: {},
            ranks: [],
            error: null
        }
    },
    created () {
        this.loadGroup()
    },
    watch: {
        '$route' () { 
            this.loadGroup()
        }
    },
    computed: {

    },
    methods: {
		...mapMutations([
			'closeTag'
		]),

        loadGroup: function() {
            this.$store.dispatch('group:load', this.id)
                .then(group => {
                    this.group = group
                    this.parseRanks()
                })
                .catch(err => { 
                    this.error = err 
                })
        },

        parseRanks: function() {
            let ranks = []

            let sportRanks = this.group.sport.ranks
            
            for(let rank of sportRanks) {
                let idx = ranks.push(rank) - 1

                let defaultRank = this.group.sport.rankNames.find(x => x.rankId === ranks[idx].id)
                let groupRank = this.group.rankNames.find(x => x.rankId === ranks[idx].id)
                if(groupRank === undefined)
                    ranks[idx].name = 'Undefined'
                else
                    ranks[idx].name = groupRank.name

                if(defaultRank === undefined) 
                    ranks[idx].default = 'Undefined'
                else
                    ranks[idx].default = defaultRank.name
            }

            this.ranks = ranks
        },

        saveGroup: function() {
            this.$http.put('/group/' + this.group.id, this.group)
        },

        handleSaveRanks: function() {
            this.$http.put(`/group/${this.group.id}/ranks`, this.ranks).then(() => 
                this.loadGroup()
            )
        },

        handleShowNewOrganization: function() {
            this.$refs.newOrganization.form.sport = this.group.sport.id
            this.$refs.newOrganization.sportChanged({ target: { value: this.group.sport.id }})
            this.$refs.newOrganization.form.group = this.group.id
            this.$refs.newOrganization.selectDisabled = true
            this.$refs.newOrganization.showModal()
        },

        handleDeleteGroup: function() {
            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete group "${this.group.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('group:remove', { id: this.group.id }).then(() => {
                        this.$swal.fire('Removed', 'The group has been successfully removed.', 'success')
                        this.closeTag(this.$route)
                    })
                }
            })
        }
    }
}
</script>