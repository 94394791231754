<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Sports</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">Sports <small>List of sports</small></h1>

        <!--<NewEstimate ref="newEstimate" @reloadEstimates="fetchItems()" />-->

        <NewSportModal ref="newSport" @reloadSports="getSports()" />

        <button class="btn btn-primary mb-2" @click="handleShowNewSport()">Create Sport</button>

        <vue-good-table 
            :columns="columns" 
            :rows="sports" 
            :lineNumbers="false"
            :search-options="{ enabled: true, placeholder: 'Search term' }" 
            :pagination-options="{ enabled: true,  position: 'bottom' }"
            @on-row-click="handleSelectSport"
            :selectOptions="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
            }">
            <div slot="selected-row-actions" style="margin: -2px 0;">
                <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                <button class="btn btn-xs btn-primary">Action 2</button>
            </div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'groupCount'">
                    {{props.row.groups.length}}
                </span>
                <span v-else-if="props.column.field == 'individualCount'">
                    {{props.row.individuals.length}}
                </span>
                <span v-else-if="props.column.field == 'image'">
                    <Avatar :src="props.row.image" :text="props.row.name.charAt(0)" size="24px" type="sport" :id="props.row.id" :changeable="false" />
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>

import NewSportModal from '@/components/modals/manager/NewSportModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'

export default {
    name: 'Sports',
    components: {
        NewSportModal,
        Avatar
    },
    data() {
        return {
            sports: [],
            columns: [{
                label: 'ID',
                field: 'id',
                width: '45px',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: '',
                field: 'image',
                sortable: false,
                width: '45px',
                tdClass: 'p-1 text-center'
            }, {
                label: 'Sport',
                field: 'name',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Groups',
                field: 'groupCount',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Individuals',
                field: 'individualCount',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Leaders',
                field: 'asdsad',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Created On',
                field: 'createdAt',
                type: 'date',
                dateInputFormat: 'MMMM dd, yyyy HH:mm:ss',
                dateOutputFormat: 'MMMM dd, yyyy HH:mm:ss',
                width: '10%',
                tdClass: 'text-center text-nowrap',
                thClass: 'text-center text-nowrap'
            }, {
                label: 'Updated On',
                field: 'updatedAt',
                type: 'date',
                dateInputFormat: 'MMMM dd, yyyy HH:mm:ss',
                dateOutputFormat: 'MMMM dd, yyyy HH:mm:ss',
                width: '10%',
                tdClass: 'text-center text-nowrap',
                thClass: 'text-center text-nowrap'
            }],
        }
    },

    created () {
        this.getSports()
    },

    methods: {
        getSports: function() {
            this.$http.get('/sport').then((response) => {
                this.sports = response.data
            })
        },

        handleShowNewSport: function() {
            this.$refs.newSport.showModal()
        },

        handleSelectSport: function(params) {
            this.$router.push({ name: 'SportOverview', params: { id: params.row.id, name: params.row.name }})
        },
    }
}
</script>