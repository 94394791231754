<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'Individuals' }">Individuals</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Individual #{{individual.id}}</a></li>
        </ol>

        <!-- END breadcrumb -->
        <!-- BEGIN page-header -->
        <div class="mb-4" v-if="individual.firstName">
            <div class="float-start me-2">
                <Avatar :src="individual.profilePicture" :text="individual.firstName.charAt(0) + individual.lastName.charAt(0)" size="64px" type="individual" :id="individual.id" :changeable="true" :hideEditText="true" />
            </div>
            <h1 class="page-header">{{individual.firstName}} {{individual.lastName}} <br><small>Header small text goes here...</small></h1>

        </div>
		<!-- END page-header -->
        <div>
            <div class="mb-2">Pending notifications list (Not judged for XYZ etc)...</div>
            <div class="float-end"><button class="btn btn-danger mb-2" disabled>Remove Individual</button></div>
        </div>

        <div class="clearfix"></div>

        <JoinGroupModal ref="joinGroup" @reloadIndividual="loadIndividual()" />
        <JoinOrganizationModal ref="joinOrganization" @reloadIndividual="loadIndividual()" />
        <JoinClubModal ref="joinClub" @reloadIndividual="loadIndividual()" />
        <AddEmergencyContactModal ref="emergencyContactModal" @addEmergencyContact="addEmergencyContact" />

        <b-card no-body v-if="individual">
            <b-tabs pills card vertical>
                <b-tab active>
                    <template slot="title">
                        <i class="fas fa-user"></i> Profile
                    </template>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="mb-10px fs-12px"><b class="text-inverse">ACCOUNT</b></div>
                            <div class="form-horizontal form-bordered form-custom mb-3">
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">ID</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.id" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Email</label>
                                        <div class="col-sm-8">
                                            <input type="email" class="form-control form-control-sm" v-model="individual.email">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Password</label>
                                        <div class="col-sm-8">
                                            <input type="password" class="form-control form-control-sm">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Created</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.createdAt" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Last Login</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.lastLogin" disabled>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="mb-10px fs-12px"><b class="text-inverse">PROFILE</b></div>
                            <div class="form-horizontal form-bordered form-custom p-0">
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">First Name</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.firstName">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Middle Name</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.middleName">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Last Name</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.lastName">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">DOB</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.dob">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Gender</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.gender" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Phone Number</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.phoneNumber" disabled>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Address</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.address">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">City</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.city">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Post Code</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.postCode">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="row">
                                        <label class="col-sm-4 col-form-label col-form-label-sm">Country</label>
                                        <div class="col-sm-8">
                                            <input type="text" class="form-control form-control-sm" v-model="individual.country">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                        <div class="col-md-3">
                            <div class="mb-10px fs-12px">
                                <b class="text-inverse">SPORTS</b>
                                <div class="float-end">
                                    <button class="btn btn-inverse btn-xs" disabled>Join Sport</button>
                                </div>
                            </div>
                            
                            <div class="card" v-for="(sport, sportIdx) in individual.sports" :key="sportIdx">
                                <div class="card-body">
                                    <h3 class="card-title">
                                        <router-link :to="{ name: 'SportOverview', params: { id: sport.id, name: sport.name }}">{{ sport.name }}</router-link>
                                    </h3>
                                    <p class="card-text">{{sport.description}}.</p>
                                    <div class="mb-3">
                                        <label class="mb-2">Rank <span class="text-danger">*</span> <span v-if="rankForSport(sport.id).verified === false && !rankVerified"><b>(This rank is unverified)</b></span></label>
                                        <select class="form-select form-select-sm form-select-inverse" :value="rankForSport(sport.id).id" @change="rankChange(sport.id, $event)" required>
                                            <option v-for="rank in sport.rankNames" :key="rank.id" :value="rank.rankId">
                                                {{ rank.name }}
                                            </option>
                                        </select>
                                    </div>

                                    <panel title="Groups" noButton="noButton" headerClass="bg-primary text-white">
                                        <template slot="buttons">
                                            <button class="btn btn-inverse btn-xs" @click="handleShowJoinGroupModal(sport.id)">Join Group</button>
                                        </template>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:90%;">Name</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(group) in groupsForSport(sport.id, false)" :key="group.id">
                                                        <td><router-link :to="{ name: 'GroupOverview', params: { id: group.id, name: group.name }}">{{ group.name }}</router-link></td>
                                                        <td><a href="javascript:;" class="btn btn-inverse btn-xs" @click="handleLeaveGroup(group.id)"><i class="fas fa-times"></i></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </panel>

                                    <panel title="Organizations" noButton="noButton" headerClass="bg-primary text-white">
                                        <template slot="buttons">
                                            <button class="btn btn-inverse btn-xs" @click="handleShowJoinOrganizationModal(sport.id)">Join Organization</button>
                                        </template>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:90%;">Name</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(organization) in organizationsForSport(sport.id, false)" :key="organization.id">
                                                        <td><router-link :to="{ name: 'OrganizationOverview', params: { id: organization.id, name: organization.name }}">{{ organization.name }}</router-link></td>
                                                        <td><a href="javascript:;" class="btn btn-inverse btn-xs" @click="handleLeaveOrganization(organization.id)"><i class="fas fa-times"></i></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </panel>

                                    <panel title="Clubs" noButton="noButton" headerClass="bg-primary text-white">
                                        <template slot="buttons">
                                            <button class="btn btn-inverse btn-xs" @click="handleShowJoinClubModal(sport.id)">Join Club</button>
                                        </template>
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th style="width:90%;">Name</th>
                                                        <th>Options</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(club) in clubsForSport(sport.id, false)" :key="club.id">
                                                        <td><router-link :to="{ name: 'ClubOverview', params: { id: club.id, name: club.name }}">{{ club.name }}</router-link></td>
                                                        <td><a href="javascript:;" class="btn btn-inverse btn-xs" @click="handleLeaveClub(club.id)"><i class="fas fa-times"></i></a></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </panel>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="mb-10px fs-12px">
                                <b class="text-inverse">EMERGENCY CONTACTS</b>
                                <div class="float-end">
                                    <button class="btn btn-inverse btn-xs" @click="handleEmergencyContactModal()">Add Contact</button>
                                </div>
                            </div>
                            <div class="card">
                                <div class="card-body">
                                    <h4 class="card-title">
                                        
                                    </h4>
                                    <p class="card-text"></p>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Number</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-for="(contact, index) in individual.emergencyContacts" class="d-flex align-items-center">
                                                    <tr v-if="contact" :key="contact.id">
                                                        <td>{{contact.name}}</td>
                                                        <td>{{contact.email}}</td>
                                                        <td>{{contact.phoneNumber}}</td>
                                                        <td><button class="btn btn-sm btn-default" @click="removeEmergencyContact(index)"><i class="fas fa-times-circle"></i></button></td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <i class="fas fa-credit-card"></i> Billing
                    </template>
                    ...
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <i class="fas fa-calendar-alt"></i> Events
                    </template>
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <i class="fas fa-photo-video"></i> Media  &nbsp;
                            <span class="badge rounded-pill bg-secondary">
                                {{ individual.media.length }}
                            </span>
                    </template>
                    <div class="col-md-8 col-sm-12">
                        <vue-good-table 
                            :columns="mediaColumns" 
                            :rows="individual.media" 
                            :lineNumbers="false"
                            :search-options="{ enabled: false, placeholder: 'Search term' }" 
                            :pagination-options="{ enabled: false,  position: 'bottom' }"
                            :selectOptions="{
                                enabled: false,
                                selectOnCheckboxOnly: true,
                                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                                selectionText: 'rows selected',
                                clearSelectionText: 'clear',
                            }">
                            <div slot="emptystate">
                                <div class="vgt-center-align vgt-text-disabled">
                                    {{ individual.firstName }} does not have any media.
                                </div>
                            </div>
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field == 'videoId'">
                                    <div v-if="props.row.state.id === 8">
                                        <button type="button" class="btn btn-xxs btn-inverse" @click="mediaWatchVideo(props.row.id, props.row.fileName)">Watch Video</button>
                                    </div>
                                    <!--<a :href="'https://www.youtube.com/watch?v=' + props.row.videoId" target="_blank" v-if="props.row.videoId !== null">https://www.youtube.com/watch?v={{props.row.videoId}}</a>-->
                                </span>
                                <span v-else-if="props.column.field == 'status'">
                                    <span class="badge" :class="props.row.state.class">{{ props.row.state.name }}</span>
                                </span>
                                <span v-else>
                                    {{props.formattedRow[props.column.field]}}
                                </span>
                            </template>
                        </vue-good-table>
                        <VideoSlide :src="mediaCurrentVideo" :key="mediaCurrentVideoIndex" inline-template>
                            <div class="ratio ratio-16x9" id="video-template" v-if="src">
                                <video id="videoPlayer" controls> 
                                    <source :src="src" type="video/mp4">
                                </video>
                            </div>
                        </VideoSlide>
                    </div>
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <i class="fas fa-sticky-note"></i> Messages
                    </template>
                </b-tab>
                <b-tab>
                    <template slot="title">
                        <i class="fas fa-stopwatch"></i> Reminders
                    </template>
                </b-tab>
            </b-tabs>
        </b-card>
    </div>
</template>

<script>

import JoinGroupModal from '@/components/modals/manager/JoinGroupModal.vue'
import JoinOrganizationModal from '@/components/modals/manager/JoinOrganizationModal.vue'
import JoinClubModal from '@/components/modals/manager/JoinClubModal.vue'
import AddEmergencyContactModal from '@/components/modals/AddEmergencyContactModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'

var VideoSlide = {
    props: ['src']
}

export default {
    props: ['id'],
    components: {
        JoinGroupModal,
        JoinOrganizationModal,
        JoinClubModal,
        AddEmergencyContactModal,
        Avatar,
        VideoSlide,
    },
    data () {
        return {
            individual: {},
            sports: [],
            rankVerified: false,

            mediaWatchingVideo: false,
            mediaCurrentVideo: '',
            mediaCurrentVideoIndex: 0,

            tab: {
                post: true,
                about: false,
                video: false,
                photo: false,
                friend: false
			},

            mediaColumns: [{
                label: 'Title',
                field: 'title',
                width: '20%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap' 
            }, {
                label: 'Description',
                field: 'description',
                width: '35%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap' 
            }, {
                label: 'Original Date',
                field: 'contentDate',
                type: 'date',
                width: '15%',
                thClass: 'text-nowrap',
                dateInputFormat: 'MMMM dd, yyyy',
                dateOutputFormat: 'MMMM dd, yyyy',
            }, {
                label: 'Video',
                field: 'videoId',
                width: '20%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap' 
            }, {
                label: 'Status',
                field: 'status',
                width: '10%',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap' 
            }]
        }
    },
    created () {
        this.loadIndividual()
        this.loadSports()
    },

    watch: {
        'individual.emergencyContacts': {
            handler() {
                this.saveIndividual()
            },
            deep: true
        },
        '$route' () { 
            this.loadIndividual()
        }
    },

    computed: {
        groupsForSport() {
            return (sportId, all) => {
                let result = []
                let sport = this.sports.find(x => x.id === sportId)

                if(sport === undefined)
                    return result

                for(let group of sport.groups)
                    if(all || this.individual.groups.find(x => x.id === group.id))
                        result.push(group)

                return result
            }
        },

        organizationsForSport() {
            return (sportId, all) => {
                let result = []
                let groups = this.groupsForSport(sportId, true)

                for(let group of groups)
                    for(let organization of group.organizations)
                        if(all || this.individual.organizations.find(x => x.id === organization.id))
                            result.push(organization)

                return result
            }
        },

        clubsForSport() {
            return (sportId, all) => {
                let result = []
                let organizations = this.organizationsForSport(sportId, true)

                for(let organization of organizations)
                    for(let club of organization.clubs)
                        if(all || this.individual.clubs.find(x => x.id === club.id))
                            result.push(club)

                return result
            }
        }
    },

    methods: {
        loadIndividual() {
            this.$http.get('/individual/' + this.id).then((response) => {
                this.individual = response.data
            })
        },

        loadSports() {
            this.$store.dispatch('sport:getAll').then((sports) => {
                this.sports = sports.data
            })
        },


        saveIndividual() {
            this.$http.put('/individual/' + this.individual.id, this.individual).then(() => {
                
            }) 
        },

        rankForSport(sportId) {
            if(this.individual.ranks === undefined)
                return -1

            let rank = this.individual.ranks.find(x => x.sportId === sportId)

            if(rank === undefined)
                return -1

            return rank
        },

        rankNameForRankId(sport, rank) {
            let rankName = sport.rankNames.find(x => x.rankId = rank.id)

            if(rankName)
                return rankName.name
            
            return 'Undefined'
        },

        rankChange(sportId, event) {
            this.$store.dispatch('individual:updateRank', { individualId: this.individual.id, sportId: sportId, rank: event.target.value }).then((ranks) => {
                this.rankVerifieid = true
            })
        },

        handleShowJoinGroupModal(sportId) {
            this.$refs.joinGroup.selectDisabled = true
            this.$refs.joinGroup.form.sport = sportId
            this.$refs.joinGroup.individual = this.individual
            this.$refs.joinGroup.groups = this.groupsForSport(sportId, true)
            this.$refs.joinGroup.showModal()
        },

        handleShowJoinOrganizationModal(sportId) {
            this.$refs.joinOrganization.selectDisabled = true
            this.$refs.joinOrganization.form.sport = sportId
            this.$refs.joinOrganization.individual = this.individual
            this.$refs.joinOrganization.organizations = this.organizationsForSport(sportId, true)
            this.$refs.joinOrganization.showModal()
        },

        handleShowJoinClubModal(sportId) {
            this.$refs.joinClub.selectDisabled = true
            this.$refs.joinClub.form.sport = sportId
            this.$refs.joinClub.individual = this.individual
            this.$refs.joinClub.clubs = this.clubsForSport(sportId, true)
            this.$refs.joinClub.showModal()
        },

        handleLeaveGroup(groupId) {
            let group = this.individual.groups.find(x => x.id === groupId)
            let index = this.individual.groups.indexOf(group)

            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to remove ${this.individual.firstName} from group "${group.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Remove'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('group:leave', { groupId: groupId, individualId: this.individual.id }).then(() => {
                        this.individual.groups.splice(index, 1)
                        this.$swal.fire('Removed', 'The individual has been removed from the group.', 'success')
                    })
                }
            })
        },

        handleLeaveOrganization(organizationId) {
            let organization = this.individual.organizations.find(x => x.id === organizationId)
            let index = this.individual.organizations.indexOf(organization)

            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to remove ${this.individual.firstName} from organization "${organization.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Remove'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('organization:leave', { organizationId: organizationId, individualId: this.individual.id }).then(() => {
                        this.individual.organizations.splice(index, 1)
                        this.$swal.fire('Removed', 'The individual has been removed from the organization.', 'success')
                    })
                }
            })
        },

        handleLeaveClub(clubId) {
            let club = this.individual.clubs.find(x => x.id === clubId)
            let index = this.individual.clubs.indexOf(club)

            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to remove ${this.individual.firstName} from club "${club.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Remove'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('club:leave', { clubId: clubId, individualId: this.individual.id }).then(() => {
                        this.individual.clubs.splice(index, 1)
                        this.$swal.fire('Removed', 'The individual has been removed from the club.', 'success')
                    })
                }
            })
        },

        handleEmergencyContactModal: function() {
            this.$refs.emergencyContactModal.showModal()
        },

        addEmergencyContact: function(item) {
            this.individual.emergencyContacts.push(item)
        },

        removeEmergencyContact: function(index) {
            this.individual.emergencyContacts.splice(index, 1)
        },

        /* Media */
        mediaWatchVideo: function(index, fileName) {
            this.mediaCurrentVideoIndex = index
            this.mediaWatchingVideo = true

            let id = fileName.split('.')

            this.mediaCurrentVideo = `${process.env.VUE_APP_API_ENDPOINT}individual/media/${id[0]}`
        },


        show: function(x) {
            this.tab.post = false
            this.tab.about = false
            this.tab.video = false
            this.tab.photo = false
            this.tab.friend = false
			
            switch (x) {
                case 'about':
                    this.tab.about = true
                    break
                case 'video':
                    this.tab.video = true
                    break
                case 'photo':
                    this.tab.photo = true
                    break
                case 'friend':
                    this.tab.friend = true
                    break
                default:
                    this.tab.post = true
                    break
			}
		}
    }
}
</script>