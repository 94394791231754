<template>
    <b-modal id="organizationModal" title="New Organization" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>New Organization</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="organization" @submit.stop.prevent="onSubmit" ref="organizationForm">
            <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" placeholder="Organization Name" v-model="form.name" required />
            </div>
            <div class="mb-3">
                <label class="mb-2">Sport <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" @change="sportChanged($event)" v-model="form.sport" required :disabled="selectDisabled">
                    <option value="0" disabled selected>Select the appropriate sport</option>
                    <option v-for="sport in sports" :key="sport.id" :value="sport.id">
                        {{ sport.name }}
                    </option>
                </select>
                <small>Select the sport this organization belongs to.</small>
            </div>
            <div class="mb-4" v-if="form.sport">
                <label class="mb-2">Group <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="form.group" required :disabled="selectDisabled">
                    <option value="0" disabled selected>Select the appropriate group</option>
                    <option v-for="group in groups" :key="group.id" :value="group.id">
                        {{ group.name }}
                    </option>
                </select>
                <small>Select the group this organization belongs to.</small>
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="creating">Create</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'NewOrganizationModal',
	data() {
        return {
            form: {
                name: '',
                sport: null,
                group: null
            },
            selectDisabled: false,
            sports: [],
            groups: [],
            error: null,
            creating: false,
        }
    },

    created() {
        this.$store.dispatch('sport:getAll').then((sports) => {
            this.sports = sports.data
        })
    },

    methods: {
        showModal() {
            this.$bvModal.show('organizationModal')
        },
        
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('organizationModal')
        },
        
        resetForm() {
            this.form = {
                name: '',
                sport: 0,
                group: 0
            }

            this.selectDisabled = false
        },

        sportChanged: function(evt) {
			let sport = this.sports.find(x => x.id === Number(evt.target.value))
			if(sport !== undefined) {
				this.groups = sport.groups
			}

			this.form.group = 0
        },

        onSubmit: function() {
            this.creating = true
            this.error = ''
            

            this.$store.dispatch('organization:create', this.form)
                .then(() => {
                    this.creating = false
                    this.hideModal()
                    this.$emit('reloadOrganizations')
                })
                .catch(err => { 
                    this.error = err
                    this.creating = false
                })
        }
    }
}
</script>
