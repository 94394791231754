<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Organizations</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">Organizations <small>List of organizations</small></h1>

        <NewOrganizationModal ref="newOrganization" @reloadOrganizations="getOrganizations()" />

        <button class="btn btn-primary mb-2" @click="handleShowNewOrganization()">Create Organization</button>

        <vue-good-table 
            :columns="columns" 
            :rows="organizations" 
            :lineNumbers="false"
            :search-options="{ enabled: true, placeholder: 'Search term' }" 
            :pagination-options="{ enabled: true,  position: 'bottom' }"
            @on-row-click="handleSelectOrganization"
            :selectOptions="{
                enabled: true,
                selectOnCheckboxOnly: true,
                selectionInfoClass: 'alert alert-info m-b-0 no-rounded-corner',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
            }">
            <div slot="selected-row-actions" style="margin: -2px 0;">
                <button class="btn btn-xs btn-primary m-r-5">Action 1</button>
                <button class="btn btn-xs btn-primary">Action 2</button>
            </div>
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'individualCount'">
                    {{props.row.individuals.length}}
                </span>
                <span v-else-if="props.column.field == 'image'">
                    <Avatar :src="props.row.image" :text="props.row.name.charAt(0)" size="24px" type="organization" :id="props.row.id" :changeable="false" />
                </span>
                <span v-else-if="props.column.field == 'name'">
                    {{props.row.name}}
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
    </div>
</template>

<script>

import NewOrganizationModal from '@/components/modals/manager/NewOrganizationModal.vue'
import Avatar from '@/components/avatar/Avatar.vue'

export default {
    name: 'Organizations',
    components: {
        NewOrganizationModal,
        Avatar
    },
    data() {
        return {
            organizations: [],
            columns: [{
                label: 'ID',
                field: 'id',
                width: '45px',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: '',
                field: 'image',
                sortable: false,
                width: '45px',
                tdClass: 'p-1 text-center'
            }, {
                label: 'Organization',
                field: 'name',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'In Sport',
                field: 'sportName',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'In Group',
                field: 'groupName',
                thClass: 'text-nowrap',
                tdClass: 'text-nowrap'
            }, {
                label: 'Individuals',
                field: 'individualCount',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Leaders',
                field: 'asdsad',
                tdClass: 'text-nowrap',
                thClass: 'text-nowrap'
            }, {
                label: 'Created On',
                field: 'createdAt',
                type: 'date',
                dateInputFormat: 'MMMM dd, yyyy HH:mm:ss',
                dateOutputFormat: 'MMMM dd, yyyy HH:mm',
                width: '10%',
                tdClass: 'text-center text-nowrap',
                thClass: 'text-center text-nowrap'
            }, {
                label: 'Updated On',
                field: 'updatedAt',
                type: 'date',
                dateInputFormat: 'MMMM dd, yyyy HH:mm:ss',
                dateOutputFormat: 'MMMM dd, yyyy HH:mm',
                width: '10%',
                tdClass: 'text-center text-nowrap',
                thClass: 'text-center text-nowrap'
            }],
        }
    },

    created () {
        this.getOrganizations()
    },

    methods: {
        getOrganizations: function() {
            this.$http.get('/organization').then((response) => {
                this.organizations = response.data
            })
        },

        handleShowNewOrganization: function() {
            this.$refs.newOrganization.showModal()
        },

        handleSelectOrganization: function(params) {
            this.$router.push({ name: 'OrganizationOverview', params: { id: params.row.id, name: params.row.name } })
        },
    }
}
</script>