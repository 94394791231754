<template>
    <b-modal id="mediaModal" title="Update Media" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>Update Media</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="media" @submit.stop.prevent="onSubmit" ref="mediaForm">
            <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Title <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" v-model="media.title" />
            </div>
            <div class="mb-4">
                <label class="mb-2">Description <span class="text-danger">*</span></label>
                <textarea type="text" class="form-control fs-13px" v-model="media.description" />
            </div>
            <div class="mb-4">
                <label class="mb-2">Original Date (Optional)</label>
                <div class="position-relative">
                    <date-picker id="dateTimeInput" class="form-control fs-13px" v-model="media.contentDate" :config="options.datepicker"></date-picker>
                </div>
                <small>The date when the content was produced.</small>
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="!create">Update</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'UpdateMediaModal',
	data() {
        return {
            media: {},
            create: true,
            error: null,
            options: {
				datepicker: {
					format: 'MMMM DD, yyyy',
					useCurrent: false
				}
			}
        }
    },

    created() {
        
    },

    methods: {
        showModal() {
            this.$bvModal.show('mediaModal')
        },

        hideModal() {
            this.media = {}
            this.$bvModal.hide('mediaModal')
        },

        onSubmit: function() {
            this.create = false

            this.error = ''

            this.$store.dispatch('individual:updateMedia', { id: this.media.id, title: this.media.title, description: this.media.description, contentDate: this.media.contentDate })
                .then(() => {
                    this.create = true
                    this.hideModal()
                    this.$emit('updateMedia')
                })
                .catch(err => { 
                    console.log(err)
                    this.error = err
                    this.create = true
                })
        }
    }
}
</script>
