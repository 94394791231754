<template>
    <b-modal id="clubModal" title="Join Club" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>Join Club</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="club" @submit.stop.prevent="onSubmit" ref="clubForm">
            <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Sport <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="form.sport" required :disabled="selectDisabled">
                    <option value="" disabled selected>Select the appropriate sport</option>
                    <option v-for="sport in sports" :key="sport.id" :value="sport.id">
                        {{ sport.name }}
                    </option>
                </select>
                <small>The Sport that these clubs belong to.</small>
            </div>
            <div class="mb-4" v-if="form.sport">
                <label class="mb-2">Club <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="form.club" required>
                    <option value="0" selected>Select a club</option>
                    <option v-for="club in clubsToJoin" :key="club.id" :value="club.id">
                        {{ club.name }}
                    </option>
                </select>
                <small>Select the Club you wish to join.</small>
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="joining">Join Club</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'JoinClubModal',
	data() {
        return {
            form: {
                sport: 0,
                club: 0
            },

            selectDisabled: false,
            individual: null,
            sports: [],
            clubs: [],
            error: null,
            joining: false,
        }
    },

    created() {
        this.$store.dispatch('sport:getAll').then((sports) => {
            this.sports = sports.data
        })
    },

    computed: {
        clubsToJoin() {
            let _this = this
            return _this.clubs.filter(clubs => {
                return _this.individual.clubs.filter(individualClubs => {
                    return individualClubs.id == clubs.id
                }).length == 0
            })
        }
    },

    methods: {
        showModal() {
            this.$bvModal.show('clubModal')
        },
        
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('clubModal')
        },
        
        resetForm() {
            this.form = {
                individual: 0,
                sport: 0,
                club: 0
            }

            this.selectDisabled = false
        },

        onSubmit: function() {
            this.joining = true
            this.error = ''

            this.$store.dispatch('club:join', { clubId: this.form.club, individualId: this.individual.id })
                .then(() => {
                    this.joining = false
                    this.hideModal()
                    this.$emit('reloadIndividual')
                })
                .catch(err => { 
                    this.error = err
                    this.joining = false
                })
        }
    }
}
</script>
