<template>
    <b-modal id="sportModal" title="New Sport" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>New Sport</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="sport" @submit.stop.prevent="onSubmit" ref="sportForm">
            <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" placeholder="Sport Name" v-model="form.name" required />
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="!create">Create</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'NewSportModal',
	data() {
        return {
            form: {
                name: '',
            },
            create: true,
            error: ''
        }
    },

    created() {

    },

    methods: {
        showModal() {
            this.$bvModal.show('sportModal')
        },
        
        hideModal() {
            this.resetForm()
            this.$bvModal.hide('sportModal')
        },

        createSport(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.$refs.sportForm.submit()
        },

        resetForm() {
            this.form = {
                name: ''
            }
        },

        onSubmit: function() {
            this.create = false
            this.error = ''

            this.$store.dispatch('sport:create', this.form)
                .then(() => {
                    this.create = true
                    this.hideModal()
                    this.$emit('reloadSports')
                })
                .catch(err => { 
                    this.error = err
                    this.create = true
                })
        }
    }
}
</script>
