export default {
    state: {
        request: '',
    },
    mutations: {
        request(state) {
            state.request = ''
        },
    },
    actions: {
        'organization:create'({ commit }, organization) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/organization', organization)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'organization:remove'({ commit }, organization) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.delete(`/organization/${organization.id}`)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'organization:load'({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.get('/organization/' + id)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        
        'organization:getAll'() {
            return new Promise((resolve, reject) => {
                this._vm.$http.get('/organization')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })
            })
        },

        'organization:join'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/organization/${data.organizationId}/join`, data)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'organization:leave'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/organization/${data.organizationId}/leave`, data)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}