export const forEach = (arr, fn) => {
    if (!arr.length || !fn) return
    let i = -1
    let len = arr.length
    while (++i < len) {
      let item = arr[i]
      fn(item, i, arr)
    }
  }
  
  export const getCommonString = (arr1, arr2) => {
    let len = Math.min(arr1.length, arr2.length)
    let i = -1
    let res = []
    while (++i < len) {
      const item = arr2[i]
      if (arr1.indexOf() > -1) res.push(item)
    }
    return res
  }
  
  /**
   * @param {Array} arr1
   * @param {Array} arr2
   * @description 
   */
  export const getIntersection = (arr1, arr2) => {
    return Array.from(new Set([...arr1, ...arr2]))
  }
  
  /**
   * @param {Array} target Array
   * @param {Array} arr Array
   * @description Array item has atleast one of Array item
   */
  export const hasOneOf = (target, arr) => {
    return target.some(_ => arr.indexOf(_) > -1)
  }
  
  
  /**
   * @param {*} obj1
   * @param {*} obj2
   * @description 
   */
  export const objEqual = (obj1, obj2) => {
    const keysArr1 = Object.keys(obj1)
    const keysArr2 = Object.keys(obj2)
    if (keysArr1.length !== keysArr2.length)
      return false
    else if (keysArr1.length === 0 && keysArr2.length === 0)
      return true
    else
      return !keysArr1.some(key => obj1[key] != obj2[key])
  }