<template>
    <div>
        <b-avatar variant="info" :button="changeable" :text="text" :size="size" :src="imageUrl" class="bg-secondary text-white" @click="newImage"></b-avatar>
        <input ref="imageInput" @change="handleImage" class="hidden-input" type="file" accept="image/*">

        <div class="text-center text-gray-600" v-if="changeable && !hideEditText">Click to edit</div>
    </div>
</template>

<style scoped>
    .hidden-input {
        height:1px;
        width:1px;
    }
</style>

<script>

export default {
	name: 'Avatar',
    props: ['src', 'text', 'size', 'type', 'id', 'changeable', 'hideEditText'],
	data() {
		return {
			image: (this.src !== null && this.src ? this.src : ''),
            data: ''
		}
	},

    created() {
        
    },

    computed: {
       imageUrl() {
            if(this.image === null || this.image.length < 32)
                return null

            let url = `${process.env.VUE_APP_API_ENDPOINT}avatar/${this.image}`

            return url
        },
    },

    methods: {
        newImage() {
            if(!this.changeable)
                return
            
            this.$refs.imageInput.click()
        },

        handleImage(evt) {
            if(!this.changeable)
                return

            const selectedImage = evt.target.files[0]
            this.createBase64Image(selectedImage)
        },

        createBase64Image(fileObject) {
            const reader = new FileReader()
            reader.onload = (evt) => {
                this.data = evt.target.result
                this.uploadImage()
            };
            reader.readAsDataURL(fileObject)
        },

        uploadImage() {
            this.$http.post(`/${this.type}/${this.id}/avatar`, { image: this.data })
                .then((response) => {
                    this.image = response.data.id
                })
                .catch((err) => {
                    this.image = null
                })
        }
    }
}
</script>
