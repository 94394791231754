<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item"><router-link :to="{ name: 'Tournaments' }">Tournaments</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Tournament #{{tournament.id}}</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">{{tournament.name}} <small>Details</small></h1>
        <!-- end page-header -->
        <CreateEditEventModal ref="eventModal" @reloadEvents="fetchEvents()" />
        <AddParticipantModal ref="addParticipant" @reloadTournament="loadTournament()" />
        <AddJudgeModal ref="addJudge" @reloadTournament="loadTournament()" />

        <template v-if="tournament.name">
            <button class="btn btn-primary mb-2" @click="handleShowNewEventModal()" :disabled="!canCreateNewEvent">New Event</button>
            <button class="btn btn-primary ms-2 mb-2" @click="handleShowAddParticipantModal()" :disabled="!canAddNewParticipant">Add Participants</button>
            <button class="btn btn-primary ms-2 mb-2" @click="handleShowAddJudgeModal()" :disabled="!canAddJudge">Add Judges</button>
        </template>

        <div class="row mb-0" v-if="tournament.judges !== undefined && tournament.judges.length < eventMinimumJudges">
            <div class="col mb-0">
                <div class="alert alert-danger fade show">Your pool of judges is too small, one or more events requires {{eventMinimumJudges}} Judges. Add more judges before the tournament begins.</div>
            </div>
        </div>
        <div class="row" v-if="tournament.name">
            <div class="col-md-3">
                <!-- BEGIN panel -->
                <panel title="Details" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-danger btn-xs" @click="handleDeleteTournament()" :disabled="!canEditTournament">Delete Tournament</button>
                    </template>
                    <div class="form-horizontal form-bordered form-custom no-border">
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">ID</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="tournament.id" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">State</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="tournament.state.name" disabled>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Name</label>
                                <div class="col-sm-8">
                                    <input type="text" class="form-control form-control-sm" v-model="tournament.name" :disabled="!canEditTournament">
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">Start Date</label>
                                <div class="col-sm-8 position-relative">
                                    <date-picker class="form-control form-control-sm" v-model="tournament.startDate" :config="options.datepicker" :disabled="!canEditTournament" required></date-picker>
                                    <small>Date from which individuals can join events.</small>
                                </div>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="row">
                                <label class="col-sm-4 col-form-label col-form-label-sm">End Date</label>
                                <div class="col-sm-8 position-relative">
                                    <date-picker class="form-control form-control-sm" v-model="tournament.endDate" :config="options.datepicker" :disabled="!canEditTournament" required></date-picker>
                                    <small>Date when entries are no longer accepted and events are started.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </panel>
                <!-- END panel -->
            </div>
            <div class="col-md-2">
                <!-- BEGIN panel -->
                <panel title="Tournament Participants" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleShowAddParticipantModal()" :disabled="!canAddNewParticipant">Add Participants</button>
                    </template>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Phone #</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(individual, individualIdx) in tournament.individuals" :key="individual.id">
                                    <td>{{individual.id}}</td>
                                    <td>{{individual.firstName}} {{individual.lastName}}</td>
                                    <td>{{individual.phoneNumber}}</td>
                                    <td><a href="javascript:;" class="btn btn-warning btn-xs" @click="handleDeleteParticipant(individualIdx)" title="Remove Participant"><i class="fas fa-user-slash"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
                <!-- END panel -->
            </div>
            <div class="col-md-2">
                <!-- BEGIN panel -->
                <panel title="Judge Pool" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleShowAddJudgeModal()" :disabled="!canAddJudge">Add Judges</button>
                    </template>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Phone #</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(judge, judgeIdx) in tournament.judges" :key="judge.id">
                                    <td>{{judge.individual.firstName}} {{judge.individual.lastName}}</td>
                                    <td>{{judge.individual.phoneNumber}}</td>
                                    <td><a href="javascript:;" class="btn btn-warning btn-xs" @click="handleDeleteJudge(judgeIdx)" title="Remove Judge"><i class="fas fa-user-slash"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
                <!-- END panel -->
            </div>
            <div class="col-md-5">
                <!-- BEGIN panel -->
                <panel title="Events" noButton="true">
                    <template slot="buttons">
                        <button class="btn btn-primary btn-xs" @click="handleShowNewEventModal()" :disabled="!canCreateNewEvent">New Event</button>
                    </template>


                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Id</th>
                                    <th>Name</th>
                                    <th>Status</th>
                                    <th>Created</th>
                                    <th>Options</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(event, eventIdx) in tournament.events" :key="event.id">
                                    <td>{{event.id}}</td>
                                    <td>{{event.name}}</td>
                                    <td>{{event.state.name}}</td>
                                    <td>{{event.createdAt}}</td>
                                    <td>
                                        <a href="javascript:;" class="btn btn-default btn-xs" @click="handleShowEditEventModal(eventIdx)"><i class="fa fa-pencil-alt"></i></a>
                                        &nbsp;
                                        <a href="javascript:;" class="btn btn-warning btn-xs" @click="handleDeleteEvent(eventIdx)"><i class="fas fa-times"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </panel>
                <!-- END panel -->
            </div>
        </div>
    </div>
</template>
<style scoped>
    .w-60 {
        width: 60px;
    }
</style>
<script>
import { mapMutations, mapActions } from 'vuex'
import CreateEditEventModal from '@/components/modals/manager/CreateEditEventModal.vue'
import AddParticipantModal from '@/components/modals/manager/AddParticipantModal.vue'
import AddJudgeModal from '@/components/modals/manager/AddJudgeModal.vue'

export default {
    props: ['id'],
    components: {
        CreateEditEventModal,
        AddParticipantModal,
        AddJudgeModal
    },
    data () {
        return {
            tournament: {},
            eventMinimumJudges: 0,
            error: null,
            options: {
                datepicker: {
                    format: 'MMMM DD, yyyy HH:mm:ss',
                    useCurrent: false,
                    icons: {
                        time: 'far fa-clock',
                        date: 'far fa-calendar',
                        up: 'fas fa-arrow-up',
                        down: 'fas fa-arrow-down',
                        previous: 'fas fa-chevron-left',
                        next: 'fas fa-chevron-right',
                        today: 'fas fa-calendar-check',
                        clear: 'far fa-trash-alt',
                        close: 'far fa-times-circle'
                    }
                }
            }
        }
    },
    created () {
        this.loadTournament()
    },
    watch: {
        tournament: {
            handler() {
                this.saveTournament()
            },
            deep: true
        },
        '$route' () { 
            this.loadTournament()
        }
    },
    computed: {
        canEditTournament() {
            return !this.tournament.state.closed
        },

        canCreateNewEvent() {
            return !this.tournament.state.closed
        },

        canAddNewParticipant() {
            return !this.tournament.state.closed
        },

        canAddJudge() {
            return !this.tournament.state.closed
        }
    },
    methods: {
		...mapMutations([
			'closeTag'
		]),

        loadTournament: function() {
            this.eventMinimumJudges = 0

            this.$store.dispatch('tournament:load', this.id)
                .then(tournament => {
                    this.tournament = tournament
                    this.loadRanks()
                    this.checkMinimumRequiredJudges()
                })
                .catch(err => { 
                    this.error = err 
                })
        },

        saveTournament: function() {
            this.$http.put('/tournament/' + this.tournament.id, this.tournament) 
        },

        loadRanks: function() {
            this.$refs.eventModal.parseRanks(this.tournament)
        },

        checkMinimumRequiredJudges: function() {
            for(const event of this.tournament.events) {
                if(this.eventMinimumJudges < event.requiredJudges) {
                    this.eventMinimumJudges = event.requiredJudges
                }
            }
        },

        fetchEvents: function() {
            this.loadTournament()
        },

        handleShowEditEventModal: function(eventIdx) {
            this.loadRanks()
            this.$refs.eventModal.setJudges(this.tournament.judges)
            this.$refs.eventModal.tournament = this.tournament
            this.$refs.eventModal.showEditModal(this.tournament.id, this.tournament.events[eventIdx])
        },

        handleShowNewEventModal: function() {
            this.loadRanks()
            this.$refs.eventModal.setJudges(this.tournament.judges)
            this.$refs.eventModal.tournament = this.tournament
            this.$refs.eventModal.showCreateModal(this.tournament.id)
        },

        handleDeleteEvent: function (eventIdx) {
            let event = this.tournament.events[eventIdx]

            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete event "${event.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('event:delete', event).then(() => {
                        this.tournament.events.splice(eventIdx, 1)
                        this.$swal.fire('Deleted', 'The event has been removed', 'success')
                    })
                }
            })
        },

        handleShowAddParticipantModal: function() {
            this.$refs.addParticipant.tournamentId = this.tournament.id
            this.$refs.addParticipant.participants = this.tournament.individuals
            this.$refs.addParticipant.showModal()
        },

        handleDeleteParticipant: function(individualIdx) {
            let individual = this.tournament.individuals[individualIdx]

            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to remove participant "${individual.firstName} ${individual.lastName}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Remove'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('tournament:participant:delete', { tournamentId: this.tournament.id, individual: individual }).then(() => {
                        this.tournament.individuals.splice(individualIdx, 1)
                        this.$swal.fire('Deleted', 'The participant has been removed from the tournament and all events.', 'success')
                    })
                }
            })
        },

        handleShowAddJudgeModal: function() {
            this.$refs.addJudge.tournamentId = this.tournament.id
            this.$refs.addJudge.judges = this.tournament.judges
            this.$refs.addJudge.showModal()
        },

        handleDeleteJudge: function(judgeIdx) {
            let judge = this.tournament.judges[judgeIdx]

            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to remove judge "${judge.individual.firstName} ${judge.individual.lastName}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Remove'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('tournament:judge:delete', { tournamentId: this.tournament.id, judge: judge }).then(() => {
                        this.tournament.judges.splice(judgeIdx, 1)
                        this.$swal.fire('Deleted', 'The judge has been removed from the tournament pool and any events they are pre-assigned to.', 'success')
                    })
                }
            })
        },

        handleDeleteTournament: function() {
            this.$swal.fire({
                title: 'Are you sure?',
                text: `You are about to delete tournament "${this.tournament.name}"`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Delete'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch('tournament:remove', this.tournament.id).then(() => {
                        this.$swal.fire('Removed', 'The tournament has been successfully removed.', 'success')
                        this.closeTag(this.$route)
                    })
                }
            })
        }
    }
}
</script>