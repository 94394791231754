import router from '../../router'

export default {
    state: {
        iat: 0,

        id: 0,
        firstName: '',
        lastName: '',
        gender: '',
        dob: '',
        age: 1,
        address: '',
        city: '',
        post_code: '',
        country: '',
        title: '',
        email: '',
        avatorImgPath: '',
        token: localStorage.getItem('token') || '',
        roles: 0,
        status: '',
        group: 0,
        media: [],


        sports: [],
        groups: [],
        organizations: [],
        clubs: []
    },
    mutations: {
        request(state) {
            state.status = 'loading'
        },
        
        auth_success(state, data) {
            state.status = 'success'
            state.token = data.token

            localStorage.setItem('token', data.token)
            this._vm.$http.defaults.headers.common['Authorization'] = data.token

            for(const key in data.individual) {
                state[key] = data.individual[key]
            }
        },

        auth_done(state) {
            state.status = ''
        },

        auth_error(state) {
            state.status = 'error'
        },

        logout(state) {
            state.status = ''
            state.token = ''
        },

        setId(state, id) {
            state.id = id
        },

        setAccess(state, access) {
            state.access = access
        },

        setToken(state, token) {
            state.token = token
        },

        setMedia(state, media) {
            state.media = media
        },

        initialiseStore(state) {
            if (!state.token.length)
                return

            this.dispatch('individual:tokenLogin')
        }
    },
    actions: {
        'individual:login'({ commit }, individual) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/login', individual)
                    .then(response => {
                        commit('auth_success', response.data)
                        resolve(response)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:tokenLogin'({ commit }) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/tokenLogin')
                    .then(response => {
                        commit('auth_success', response.data)
                        resolve(response)
                    })
                    .catch(err => {
                        commit('auth_error')
                        localStorage.removeItem('token')
                        router.push('/login')
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:logout'({ commit }) {
            return new Promise((resolve) => {
                commit('logout')
                localStorage.removeItem('token')
                delete this._vm.$http.defaults.headers.common['Authorization']
                resolve()
            })
        },
        'individual:forgotpassword'({ commit }, individual) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/forgotpassword', individual)
                    .then(response => {
                        commit('auth_done')
                        resolve(response)
                    })
                    .catch(err => {
                        commit('auth_error')
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:forgotpasswordcheck'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/forgotpasswordcheck', data)
                    .then(response => {
                        commit('auth_done')
                        resolve(response)
                    })
                    .catch(err => {
                        commit('auth_error')
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:forgotpasswordcomplete'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/forgotpasswordcomplete', data)
                    .then(response => {
                        commit('auth_done')
                        resolve(response)
                    })
                    .catch(err => {
                        commit('auth_error')
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:register'({ commit }, individual) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/register', individual)
                    .then(response => {
                        commit('auth_done')
                        resolve(response)
                    })
                    .catch(err => {
                        commit('auth_error')
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:getMedia'({ commit }) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.get('/individual/media')
                    .then(response => {
                        commit('setMedia', response.data)
                        resolve(response.data)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })
            })
        },
        'individual:updateMedia'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/individual/media/' + data.id, data)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })
            })
        },

        'individual:updateRank'({ commit}, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/individual/${data.individualId}/rank/`, data)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })
            })
        }
    },
    getters: {
        token: state => state.token,
        isLoggedIn: state => !!state.token,
        roles: state => state.roles,
        authStatus: state => state.status,
        currentGroup: state => state.group,
        fullName: state => {
            return (state.firstName + ' ' + state.lastName)
        }
    },
}
