import { ValidationProvider, ValidationObserver, extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'

Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign message
    })
})
  
configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty']
        // ...
    }
})

extend('required', {
    message: 'This field is required'
})

extend('email', {
    message: 'Email address is not valid'
})

extend('matchemail', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Email addresses entered do not match'
})

extend('matchpassword', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Passwords entered do not match'
})

extend('objectNotEmpty', {
    validate: (value) => {
      if (value > 0) {
        return true
      }
      return false
    },
    message: 'Select an option'
})

export default {
    ValidationProvider: ValidationProvider,
    ValidationObserver: ValidationObserver
}