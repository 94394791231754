<template>
    <b-modal id="contactModal" title="Add Contact" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>Add Contact</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="media" @submit.stop.prevent="onSubmit" ref="contactForm">
            <div class="login-form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" v-model="name" />
            </div>
            <div class="mb-4">
                <label class="mb-2">Email Address <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" v-model="email" />
            </div>
            <div class="mb-4">
                <label class="mb-2">Phone Number <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" v-model="phoneNumber" />
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="primary" type="submit">Add</b-button>

        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'AddEmergencyContactModal',
	data() {
        return {
            name: '',
            email: '',
            phoneNumber: '',
            error: null
        }
    },

    created() {
        
    },

    methods: {
        showModal() {
            this.$bvModal.show('contactModal')
        },

        hideModal() {
            this.name = ''
            this.email = ''
            this.phoneNumber = ''
            this.$bvModal.hide('contactModal')
        },

        onSubmit: function() {
            this.error = ''

            this.$emit('addEmergencyContact', {
                name: this.name,
                email: this.email,
                phoneNumber: this.phoneNumber
            })

            this.hideModal()
        }
    }
}
</script>
