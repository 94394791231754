<template>
	<!-- BEGIN login -->
	<div class="login login-v1">
		<!-- BEGIN login-container -->
		<div class="login-container">
			<!-- BEGIN login-header -->
			<div class="login-header">
				<div class="brand">
					<div class="d-flex align-items-center">
						<span class="logo"></span> <b>User</b> Login
					</div>
					<small>Sport Manager</small>
				</div>
				<div class="icon">
					<i class="fa fa-lock"></i>
				</div>
			</div>
			<!-- END login-header -->
			
			<!-- BEGIN login-body -->
			<div class="login-body">
				<!-- BEGIN login-content -->
				<div class="login-content fs-13px">
					<div class="login-form-error text-danger" v-if="error">{{ error }}</div>
					<form @submit.prevent="login">
						<div class="form-floating mb-20px">
							<input type="email" class="form-control fs-13px h-45px" id="emailAddress" v-model="email" placeholder="Email Address" required />
							<label for="emailAddress" class="d-flex align-items-center py-0">Email Address</label>
						</div>
						<div class="form-floating mb-20px">
							<input type="password" class="form-control fs-13px h-45px" id="password" v-model="password" placeholder="Password" required />
							<label for="password" class="d-flex align-items-center py-0">Password</label>
						</div>
						<div class="form-check mb-20px">
							<input class="form-check-input" type="checkbox" value="" id="rememberMe" v-model="rememberme" />
							<label class="form-check-label" for="rememberMe">
								Remember Me
							</label>
						</div>
						<div class="login-buttons mb-4">
							<button type="submit" class="btn h-45px btn-primary d-block w-100 btn-lg">Sign In</button>
						</div>
						<div>
							Don't have an account? <router-link to="/register">Register a new account</router-link>
						</div>
						<div class="mt-2">
							Forgot your password? <router-link to="/forgotpassword">Reset your password</router-link>
						</div>
					</form>
				</div>
				<!-- END login-content -->
			</div>
			<!-- END login-body -->
		</div>
		<!-- END login-container -->
	</div>
	<!-- END login -->
</template>

<script>
import AppOptions from '../config/AppOptions.vue'

export default {
	data() {
		return {
			error: "",
            email : "",
			password : "",
			rememberme: false,
		}
	},
	created() {
		AppOptions.appEmpty = true
	},
	beforeRouteLeave (to, from, next) {
		AppOptions.appEmpty = false
		next();
	},
	methods: {
        login: function () {
            let email = this.email 
			let password = this.password
			let rememberme = this.rememberme
            this.error = ''
            this.$store.dispatch('individual:login', { email, password, rememberme })
                .then(() => this.$router.push('/'))
                .catch(err => { 
                    this.error = err 
                })
        },
	}
}
</script>