<template>
    <div>
        <!-- begin breadcrumb -->
        <ol class="breadcrumb hidden-print float-xl-end">
            <li class="breadcrumb-item"><router-link :to="{ path: '/' }">Home</router-link></li>
            <li class="breadcrumb-item active"><a href="javascript:;">Tournaments</a></li>
        </ol>
        <!-- end breadcrumb -->
        <!-- begin page-header -->
        <h1 class="page-header">Tournaments <small>List of tournaments</small></h1>
        <!-- end page-header -->

        <NewTournamentModal ref="newTournament" @reloadTournaments="getTournaments()" />

        <button class="btn btn-primary mb-2" @click="handleShowNewTournament()">Create Tournament</button>

        <div class="row" v-if="tournaments">
            <div class="col-md-3" role="button" v-for="(tournament, tournamentIdx) in tournaments" v-bind:key="tournament.id" @click="handleSelectTournament(tournament.id)">
                <div class="card border-0 text-truncate mb-3 text-white" :class="{ 'bg-gray-900' : (tournament.status < 3), 'bg-gray-700' : (tournament.status === 3) }">
                    <div class="card-body">
                        <div class="mb-3 text-gray-500">
                            <b class="mb-3 lead">{{tournament.name}}</b>
                            <span class="ms-2">
                                <i class="fa fa-info-circle" data-bs-toggle="popover" data-bs-trigger="hover" data-bs-title="Conversion Rate" data-bs-placement="top" data-bs-content=""></i>
                            </span>
                            <b class="float-end text-warning" v-if="tournament.state.closed">This tournament is {{tournament.state.name}}</b>
                        </div>

                        <div class="d-flex align-items-center mb-4">
                            <h3 class="text-white mb-0">
                                {{maybePluralize(count(tournament.events), 'Event')}}
                            </h3>
                            <div class="ms-auto" style="position: relative;">
                                <div class="resize-triggers">
                                    <div class="expand-trigger">
                                        <div style="width: 161px; height: 29px;"></div>
                                    </div>
                                    <div class="contract-trigger"></div>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex mb-2">
                            <div class="d-flex align-items-center">
                                Entries Begin: {{tournament.startDate}}
                            </div>
                        </div>
                        <div class="d-flex mb-2">
                            <div class="d-flex align-items-center">
                                Tournament Begins: {{tournament.endDate}}
                            </div>
                        </div>

                        <div class="d-flex mb-2">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-circle text-primary fs-8px me-2"></i>
                                    Participants
                            </div>
                            <div class="d-flex align-items-center ms-auto">
                                <div class="w-50px text-end ps-2 fw-bold">
                                    {{tournament.individuals.length}}
                                </div>
                            </div>
                        </div>

                        <div class="d-flex mb-2">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-circle text-warning fs-8px me-2"></i>
                                Events Pending
                            </div>
                            <div class="d-flex align-items-center ms-auto">
                                <div class="w-50px text-end ps-2 fw-bold">
                                    {{eventsPending(tournamentIdx)}}
                                </div>
                            </div>
                        </div>

                        <div class="d-flex">
                            <div class="d-flex align-items-center">
                                <i class="fa fa-circle text-lime fs-8px me-2"></i>
                                Events concluded
                            </div>
                            <div class="d-flex align-items-center ms-auto">
                                <div class="w-50px text-end ps-2 fw-bold">
                                    {{eventsConcluded(tournamentIdx)}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <h5 class="mt-5">There are currently no tournaments, create one.</h5>
        </div>
    </div>
</template>

<script>

import NewTournamentModal from '@/components/modals/manager/NewTournamentModal.vue'
import { maybePluralize } from '../libs/util.js'

export default {
    name: 'Tournaments',
    components: {
        NewTournamentModal
    },
 
    data() {
        return {
            tournaments: []
        }
    },

    beforeCreate() {
        this.maybePluralize = maybePluralize
    },
    
    created() {
        this.getTournaments()
    },

    computed: {

    },

    methods: {
        getTournaments: function() {
            this.$http.get('/tournament').then((response) => {
                this.tournaments = response.data
            })
        },

        handleShowNewTournament: function() {
            this.$refs.newTournament.showModal()
        },

        handleSelectTournament: function(id) {
            this.$router.push({ name: 'TournamentOverview', params: { id: id } })
        },

        eventsPending(tournamentIdx) {
            let events = this.tournaments[tournamentIdx].events.filter(x => x.status === 2)

            if(events === undefined)
                return 0

            return events.length
        },

        eventsConcluded(tournamentIdx) {
            let events = this.tournaments[tournamentIdx].events.filter(x => x.status === 3)

            if(events === undefined)
                return 0

            return events.length
        },

        count: function(map) {
            return map.length
        }
    }
}

</script>