export default {
    state: {
        request: '',
    },
    mutations: {
        request(state) {
            state.request = ''
        },
    },
    actions: {
        'group:create'({ commit }, group) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post('/group', group)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'group:remove'({ commit }, group) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.delete(`/group/${group.id}`)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'group:load'({ commit }, id) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.get('/group/' + id)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        
        'group:getAll'() {
            return new Promise((resolve, reject) => {
                this._vm.$http.get('/group')
                    .then(response => {
                        resolve(response)
                    })
                    .catch(err => {
                        reject(err.response.data.message)
                    })
            })
        },

        'group:join'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/group/${data.groupId}/join`, data)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },

        'group:leave'({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit('request')
                this._vm.$http.post(`/group/${data.groupId}/leave`, data)
                    .then(response => {
                        if(response.data) {
                            resolve(response.data)
                        } else {
                            reject(null)
                        }
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
}