<template>
    <b-modal id="eventModal" :title="title" button-size="sm" hide-footer>
        <template #modal-header="{ close }">
            <h5>{{ title }}</h5>
            <!-- Emulate built in modal header close button action -->
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close()"></button>
        </template>
        <b-form name="event" @submit.stop.prevent="onSubmit" ref="eventForm">
            <div class="form-error text-danger" v-if="error">{{ error }}</div>
            <div class="mb-3">
                <label class="mb-2">Name <span class="text-danger">*</span></label>
                <input type="text" class="form-control fs-13px" placeholder="Event Name" v-model="event.name" required />
            </div>
            <div class="mb-3">
                <label class="mb-2">Competition Type <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="event.type" required>
                    <option v-for="type in types" :key="type.id" :value="type.id">
                        {{ type.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label class="mb-2">Judges Required <span class="text-danger">*</span></label>
                <input type="number" class="form-control fs-13px" v-model="event.requiredJudges">
            </div>
            <div class="mb-3">
                <label class="mb-2">Judges Minimum Rank <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" v-model="event.judgesMinimumRank" required>
                    <option v-for="rank in ranks" :key="rank.rank" :value="rank.rank">
                        {{ rank.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3">
                <label class="mb-2">Pre-defined Judges <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" multiple v-model="event.presetJudges">
                    <option v-for="judge in judges" :key="judge.id" :value="judge.id">
                        {{judge.individual.firstName}} {{judge.individual.lastName}}
                    </option>
                </select>
                <small class="text-danger">Judges that will be automatically assigned to this event from the pool up to the max.</small>
            </div>
            <div class="mb-3" v-if="event.type === 3 && event.requiredJudges >= 5">
                <!-- If event is Point Based and required Judges is higher than 5 -->
                <label class="mb-2">Remove highest and lowest judge scores from total<span class="text-danger">*</span></label>
                <div class="col">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="event.onlyUseMedianScores">
                        <label class="form-check-label">{{ event.onlyUseMedianScores }}</label>
                    </div>
                </div>
                <small class="text-danger">Turning this on will remove the highest and lowest scores from the total when determining overall placement.</small>
            </div>
            <div class="mb-3">
                <label class="mb-2">Judge Timeout (Hours) <span class="text-danger">*</span></label>
                <input type="number" class="form-control fs-13px" placeholder="1" v-model="event.judgeScoringTimeout">
                <small>How long judges have to begin scoring before being replaced by another judge.</small>
            </div>
            <div class="mb-3">
                <label class="mb-2">Age Range <span class="text-danger">*</span></label>
                <div class="input-group">
                    <input type="number" class="form-control fs-13px" placeholder="Min" v-model="event.minimumAge">
                    <span class="input-group-text">-</span>
                    <input type="number" class="form-control fs-13px" placeholder="Max" v-model="event.maximumAge">
                </div>
                <small>Minimum and Maximum age required to join event.</small>
            </div>
            <div class="mb-3">
                <label class="mb-2">Rank Requirement <span class="text-danger">*</span></label>
                <div class="input-group">
                    <select class="form-select fs-13px" v-model="event.minimumRank" required>
                        <option v-for="rank in ranks" :key="rank.rank" :value="rank.rank">
                            {{ rank.name }}
                        </option>
                    </select>
                    <span class="input-group-text">to</span>
                    <select class="form-select fs-13px" v-model="event.maximumRank" required>
                        <option v-for="rank in ranks" :key="rank.rank" :value="rank.rank">
                            {{ rank.name }}
                        </option>
                    </select>
                </div>
                <small>Required minimum and maximum rank for the event.</small>
            </div>
            <div class="mb-3">
                <label class="mb-2">Gender Requirement <span class="text-danger">*</span></label>
                <div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" value="Male" v-model="event.requiredGender">
                        <label class="form-check-label">Male</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" value="Female" v-model="event.requiredGender">
                        <label class="form-check-label">Female</label>
                    </div>
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" value="Other" v-model="event.requiredGender">
                        <label class="form-check-label">Other</label>
                    </div>
                </div>
                <small>Genders allowed to join the event.</small>
            </div>
            <hr v-if="event.type === 3">
            <div class="mb-3" v-if="event.type === 3"> <!-- Points Based -->
                <label class="mb-2">Display Judges Scores to All <span class="text-danger">*</span></label>
                <div class="col">
                    <div class="form-check form-check-inline">
                        <input class="form-check-input" type="checkbox" v-model="event.displayJudgeScoresToAll">
                        <label class="form-check-label">{{ event.displayJudgeScoresToAll }}</label>
                    </div>
                </div>
                <small>Choose whether to display the overall average in each score category to all users.</small>
            </div>
            <hr v-if="event.type === 3">
            <div class="mb-3" v-if="event.type === 3"> <!-- Points Based -->
                <label class="mb-2">Score Categories <span class="text-danger">*</span></label> <button type="button" class="btn btn-primary btn-xs" @click="addScoreCategory()">Add</button>
                
                <div v-for="(scoreCategory, index) in event.scoreCategories" :key="index">
                    <div class="input-group mb-3">
                        <span class="input-group-text">Name</span>
                        <input type="text" class="form-control" aria-label="Name" v-model="scoreCategory.name" required>
                        <span class="input-group-text">Weight</span>
                        <input type="text" class="form-control" aria-label="Weight" v-model="scoreCategory.weight" required>
                        <span class="input-group-text cursor-pointer" @click="removeScoreCategory(index)">X</span>

                    </div>
                </div>
            </div>
            <div class="mb-3" v-if="event.type === 3"> <!-- Points Based -->
                <label class="mb-2">Participants <span class="text-danger">*</span></label>
                <select class="form-select fs-13px" size="10" multiple v-model="individuals">
                    <option v-for="individual in tournamentMembers" :key="individual.id" :value="individual.id">
                        {{individual.firstName}} {{individual.lastName}} 
                    </option>
                </select>
                <small class="text-danger">Temporary participant allocation.</small>
            </div>
            <b-button variant="default" @click="hideModal()">Cancel</b-button>
            &nbsp;
            <b-button variant="default" @click="resetForm()">Reset</b-button>
            &nbsp;
            <b-button variant="primary" type="submit" :disabled="updating" v-if="!editMode">Create</b-button>
            <b-button variant="primary" type="submit" :disabled="updating" v-if="editMode">Update</b-button>
        </b-form>
    </b-modal>
</template>

<script>

export default {
	name: 'CreateEditEventModal',
	data() {
        return {
            editMode: false,
            tournament: {},
            event: {
                name: '',
                type: 1,
                requiredJudges: 1,
                onlyUseMedianScores: 0,
                presetJudges: [],
                judgesMinimumRank: 1,
                judgeScoringTimeout: 24,
                minimumAge: 1,
                maximumAge: 100,
                minimumRank: -1,
                maximumRank: -1,
                requiredGender: [],
                tournamentId: 0,
                displayJudgeScoresToAll: false,
                scoreCategories: []
            },
            types: [
                { id: 1, name: 'Head to Head Single Elimination' },
                { id: 2, name: 'Head to Head Double Elimination' },
                { id: 3, name: 'Point Based Competition' }
            ],
            ranks: [],
            judges: [],
            individuals: [],
            tournamentMembers: [],
            error: null,
            updating: false,
            options: {
                datepicker: {
                    format: 'MMMM DD, yyyy',
                    useCurrent: false
                }
            }
        }
    },

    created() {

    },

    computed: {
        title() {
            if(this.editMode)
                return 'Edit Event'

            return 'New Event'
        }
    },

    methods: {
        parseRanks(tournament) {
            let ranks = []

            let sportRanks = tournament.sport.ranks

            for(let rank of sportRanks) {
                let idx = ranks.push(rank) - 1

                let defaultRank = tournament.sport.rankNames.find(x => x.rankId === ranks[idx].id)
                let groupRank = tournament.group.rankNames.find(x => x.rankId === ranks[idx].id)
                if(groupRank === undefined || groupRank === 'Undefined')
                    ranks[idx].name = defaultRank.name
                else
                    ranks[idx].name = `${defaultRank.name} - ${groupRank.name}`
            }

            this.ranks = ranks
        },

        setJudges(judges) {
            this.judges = judges
        },

        setTournamentMembers() {
            this.tournamentMembers = this.tournament.individuals

            this.setIndividuals()
        },

        setIndividuals() {
            let members = []

            if(!this.event.eventEntries)
                return

            for(let entrant of this.event.eventEntries) {
                members.push(entrant.individual.id)
            }

            this.individuals = members
        },

        showEditModal(tournamentId, event) {
            this.editMode = true
            this.event = event
            this.event.tournamentId = tournamentId
            this.$bvModal.show('eventModal')

            this.setTournamentMembers()
        },

        showCreateModal(tournamentId) {
            this.editMode = false
            //this.resetForm()
            this.event.tournamentId = tournamentId
            this.$bvModal.show('eventModal')

            this.setTournamentMembers()
        },

        hideModal() {
            //this.resetForm()
            this.$bvModal.hide('eventModal')
        },

        resetForm() {
            this.event = {
                name: '',
                type: 1,
                requiredJudges: 1,
                onlyUseMedianScores: 0,
                presetJudges: [],
                judgesMinimumRank: 1,
                judgeScoringTimeout: 24,
                minimumAge: 1,
                maximumAge: 100,
                minimumRank: -1,
                maximumRank: -1,
                requiredGender: [],
                tournamentId: 0,
                displayJudgeScoresToAll: false,
                scoreCategories: []
            }
        },

        saveEvent(ev) {
            ev.preventDefault()
            this.$refs.eventForm.submit()
        },

        addScoreCategory() {
            this.event.scoreCategories.push({
                name: '',
                weight: weight
            })

            let weight = Number(parseFloat(100).toFixed(3) / this.event.scoreCategories.length).toFixed(2)

            for(let i in this.event.scoreCategories) {
                this.event.scoreCategories[i].weight = weight
            }
        },

        removeScoreCategory(index) {
            this.event.scoreCategories.splice(index, 1)

            let weight = Number(parseFloat(100).toFixed(2) / this.event.scoreCategories.length).toFixed(2)

            // Update all the weights to be even.
            for(let i in this.event.scoreCategories) {
                this.event.scoreCategories[i].weight = weight
            }
        },

        onSubmit: function() {
            this.updating = true
            this.error = ''

            if(this.event.type === 3) {

                let total = 0

                for(let i in this.event.scoreCategories) {
                    total += parseFloat(this.event.scoreCategories[i].weight)
                }

                if(Number(total).toFixed(2) > parseFloat(100.01) || Number(total).toFixed(2) < parseFloat(99.99)) {
                    this.error = 'Score Category weights must be allocated to a total of 100 +- 0.01%'
                }
            }

            if(this.error.length > 0) {
                this.updating = false
                return this.$notify({
					group: 'error',
					title: `Error`,
					text : this.error,
					type: 'error'
				})
            }

            this.event.individuals = this.individuals

            this.$store.dispatch(this.editMode ? 'event:update' : 'event:create', this.event)
                .then(() => {
                    this.updating = false
                    this.hideModal()
                    this.resetForm()
                    this.$emit('reloadEvents')
                })
                .catch(err => { 
                    this.updating = false
                    this.error = err.response.data.message
                })
        }
    }
}
</script>
